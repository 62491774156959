// import update from 'immutability-helper';

import actions from './actions';

const initState = {
  campaigns: [],
  currentCampaign: null,
  loading: false,
  error: '',
};

export default function wifiCampaignReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_WIFI_CAMPAIGNS:
    case actions.CREATE_WIFI_CAMPAIGN:
    case actions.DELETE_WIFI_CAMPAIGN:
    case actions.GET_CURRENT_WIFI_CAMPAIGNS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actions.GET_CURRENT_WIFI_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        currentCampaign: action.campaign,
        loading: false,
        error: '',
      };
    case actions.GET_WIFI_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.campaigns,
        loading: false,
        error: '',
      };
    case actions.CREATE_WIFI_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.campaign],
        loading: false,
        error: '',
      };
    case actions.GET_WIFI_CAMPAIGNS_ERROR:
    case actions.GET_CURRENT_WIFI_CAMPAIGNS_ERROR:
    case actions.CREATE_WIFI_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.UPDATE_WIFI_CAMPAIGN_SUCCESS: {
      const { campaigns } = state;
      // console.log('campaign in UPDATE_CAMPAIGN_SUCCESS', action.campaign);
      const newCampaigns = campaigns.map(camp => {
        if (camp._id === action.campaign._id) return action.campaign;
        return camp;
      });
      return {
        ...state,
        campaigns: newCampaigns,
        loading: false,
        error: ''
      };
    }
    default:
      return state;
  }
}
