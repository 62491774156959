import actions from './actions';

const initState = {
  total: 0,
  leads: [],
  error: '',
  loading: false,
};

export default function saleLeadReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_SALE_LEAD:
    case actions.DELETE_SALE_LEAD:
    case actions.UPDATE_SALE_LEAD:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case actions.DELETE_SALE_LEAD_ERROR:
    case actions.LOAD_SALE_LEAD_ERROR:
    case actions.UPDATE_SALE_LEAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOAD_SALE_LEAD_SUCCESS: {
      const { leads } = state;
      const { data, loadMore } = action;
      const newLeads = loadMore
        ? [...leads, ...data.leads]
        : data.leads;
      // console.log('newProfiles', newProfiles);
      return {
        ...state,
        total: data.total,
        leads: newLeads,
        loading: false,
        error: '',
      };
    }
    case actions.DELETE_SALE_LEAD_SUCCESS: {
      const { leads, total } = state;
      return {
        ...state,
        loading: false,
        error: '',
        total: total - 1,
        leads: leads.filter(l => l._id !== action.id),
      };
    }
    case actions.UPDATE_SALE_LEAD_SUCCESS: {
      const { leads } = state;
      return {
        ...state,
        loading: false,
        error: '',
        leads: leads.map(l => {
          if (l._id === action.lead._id) {
            return action.lead;
          }
          return l;
        })
      };
    }
    default:
      return state;
  }
}
