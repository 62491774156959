import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
// import pageActions from '../../redux/socialPage/actions';
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";

const { Header } = Layout;
const { toggleCollapsed } = appActions;
// const { selectPage } = pageActions;
const customizedTheme = themes[themeConfig.theme];
// const { Option, OptGroup } = Select;

class Topbar extends Component {
  /*
  handleChange = value => {
    // console.log('handleChange', value);
    const { pages, selectPage, history } = this.props;
    const selectedPage = pages.filter(page => page.eid === value);
    // console.log('selectedPage', selectedPage);
    selectPage(selectedPage.length ? selectedPage[0] : null);
    history.push(`/dashboard/page?eid=${selectedPage[0].eid}`)
  };
  */

  render() {
    const { toggleCollapsed, user } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser user={user} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    user: state.Auth,
  }),
  { toggleCollapsed }
)(withRouter(Topbar));
