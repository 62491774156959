import actions from './actions';

const initState = {
  devices: [],
  loading: false,
  error: '',
  message: '',
};

export default function reducer(state = initState, action) {
  switch(action.type) {
    case actions.CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case actions.CREATE_DEVICE:
    case actions.LOAD_DEVICES:
    case actions.UPDATE_DEVICE:
    case actions.DELETE_DEVICE:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case actions.CREATE_DEVICE_SUCCESS: {
      const { devices } = state;
      return {
        ...state,
        devices: [...devices, action.device],
        loading: false,
        error: '',
        message: 'Created device',
      };
    }
    case actions.CREATE_DEVICE_ERROR:
    case actions.LOAD_DEVICES_ERROR:
    case actions.UPDATE_DEVICE_ERROR:
    case actions.DELETE_DEVICE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actions.LOAD_DEVICES_SUCCESS: {
      // const { devices } = state;
      return {
        ...state,
        devices: action.devices,
        loading: false,
        error: '',
      };
    }
    case actions.UPDATE_DEVICE_SUCCESS: {
      const { devices } = state;
      const newDevices = devices.map(dev => {
        if (dev.nas_id === action.device.nas_id) return action.device;
        return dev;
      });
      return {
        ...state,
        devices: newDevices,
        loading: false,
        error: '',
        message: 'Device updated!'
      };
    }
    case actions.DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: state.devices.filter(dev => dev.nas_id !== action.device.nas_id),
        loading: false,
        error: '',
        message: 'Device deleted!'
      };
    default:
      return state;
  }
};
