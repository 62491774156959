export default {
  // apiUrl: 'http://localhost:4040',
  // apiUrl: 'http://api.smartwifi.vn',
  apiUrl: window.location.hostname === 'localhost'
    ? 'http://localhost:4040'
    : 'https://api.wifibiz.net',
};

const siteConfig = {
  siteName: 'WifiBiz',
  siteIcon: 'ion-flash',
  footerText: `WifiBiz ©${new Date().getFullYear()}`,
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'vietnamese'; // english

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

const googleConfig = {
  apiKey: 'AIzaSyCwbnyvdoeCjbcC9aDeTZWNzd9EgzdH2g0',
};

const matomo = {
  url: 'https://analytics.smartwifi.vn',
  apiToken: '5b2b4c273ed31692b6bc4d45ce72ddd3'
}

const zaloOA = {
  appId: '4455673755577775511',
  redirectUrl: window.location.hostname === 'localhost'
    ? 'http://localhost:4040/dashboard/page-zalo-create'
    : 'https://admin.wifibiz.net/dashboard/page-zalo-create'
}
export { siteConfig, language, themeConfig, jwtConfig, googleConfig, matomo, zaloOA };
