import update from 'immutability-helper';
import actions from './actions';

const initState = {
  pageItems: [],
  error: '',
  loading: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_PAGE_ITEMS:
    case actions.CREATE_PAGE_ITEM:
    case actions.EDIT_PAGE_ITEM:
    case actions.DELETE_PAGE_ITEM:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case actions.LOAD_PAGE_ITEMS_SUCCESS: {
      const { pageItems } = state;
      const { skip } = action;
      return {
        ...state,
        pageItems: skip === 0 ? action.items : [ ...pageItems, action.items ],
        error: '',
        loading: false,
      };
    }
    case actions.LOAD_PAGE_ITEMS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actions.CREATE_PAGE_ITEM_SUCCESS: {
      // const { pageItems } = state;
      // return {
      //   ...state,
      //   pageItems: [ action.item, ...pageItems ],
      //   error: '',
      //   loading: false,
      // };
      return update(state, {
        pageItems: {
          $unshift: [ action.item ]
        },
        loading: { $set: false },
        error: { $set: '' }
      });
    }
    case actions.CREATE_PAGE_ITEM_ERROR:
    case actions.EDIT_PAGE_ITEM_ERROR:
    case actions.DELETE_PAGE_ITEM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.EDIT_PAGE_ITEM_SUCCESS: {
      const { pageItems } = state;
      const newPageItems = pageItems.map(item => {
        if (item.eid === action.item.eid) return action.item;
        return item;
      });
      // return {
      //   ...state,
      //   pageItems: newPageItems,
      //   loading: false,
      //   error: '',
      // };
      return update(state, {
        pageItems: {
          $set: newPageItems
        },
        loading: { $set: false },
        error: { $set: '' }
      });
    }
    case actions.DELETE_PAGE_ITEM_SUCCESS: {
      // console.log('delete in success', action.item);
      const { pageItems } = state;
      const newPageItems = pageItems.filter(item => item.eid !== action.item.eid);
      // console.log('new pageItems', newPageItems);
      // return {
      //   ...state,
      //   pageItems: newPageItems,
      //   loading: false,
      //   error: action.error,
      // };
      return update(state, {
        pageItems: {
          $set: newPageItems
        },
        loading: { $set: false },
        error: { $set: action.error }
      });
    }
    case actions.MODIFY_PAGE_ITEM_ATTR: {
      const { pageItems } = state;
      const newPageItems = pageItems.map(item => {
        if (item.eid === action.eid) item[action.attribute] += action.value;
        return item;
      });
      return {
        ...state,
        pageItems: newPageItems,
      }
    }
    default:
      return state;
  }
}
