import { all, fork, takeEvery, select, call, put } from 'redux-saga/effects';
import actions from './actions';
import API_ENDPOINT from '../../settings';
import { makeSelectPageToken } from '../socialPage/selector';
import request from '../../helpers/request';

/* eslint func-names: ["error", "never"] */
/* eslint-disable no-return-await */

export function* loadCustomProfiles() {
  yield takeEvery(actions.LOAD_CUSTOM_PROFILE, function*(action) {
    const pageToken = yield select(makeSelectPageToken());

    const limit = action.limit ? action.limit : 50;
    const skip = action.skip ? action.skip : 0;
    const { fromDate, toDate } = action;
    // console.log('pageToken', pageToken);
    const url = `${API_ENDPOINT.apiUrl}/custom-users?limit=${limit}&skip=${skip}&fromDate=${fromDate}&toDate=${toDate}`;

    try {
      const result = yield call(
        request,
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${pageToken}`,
          },
        }
      );
      // console.log('loaded custom profiles', url, result);
      yield put(actions.loadCustomProfilesSuccess(result));

    } catch (error) {
      // console.log('load custom profiles error', error.message);
      yield put({
        type: actions.LOAD_CUSTOM_PROFILE_ERROR,
        error: error.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadCustomProfiles),
  ]);
}
