import { all, fork, takeLatest, select, call, put } from 'redux-saga/effects';
import { message, notification } from 'antd';
import actions from './actions';
import API_ENDPOINT from '../../settings';
// import { makeSelectToken } from '../auth/selector';
import { makeSelectPageToken } from '../socialPage/selector';
import request from '../../helpers/request';
// import { DefaultBlockValue } from '../../containers/Campaign/constants';

/* eslint-disable no-return-await */

export function* getCurrentWifiCampaigns() {
  yield takeLatest(actions.GET_CURRENT_WIFI_CAMPAIGNS, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(
        request,
        `${API_ENDPOINT.apiUrl}/wifi-campaign/current?pageId=${action.pageId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${pageToken}`,
          },
        }
      );
      console.log('loaded current campaigns', result);
      yield put(actions.getCurrentWifiCampaignsSuccess(result));
    } catch (err) {
      notification.error({ message: err.message });
      yield put(actions.getCurrentWifiCampaignsError(err.message));
    }
  });
}

export function* getWifiCampaigns() {
  yield takeLatest(actions.GET_WIFI_CAMPAIGNS, function*() {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/wifi-campaign`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      console.log('loaded campaigns', result);
      yield put(actions.getWifiCampaignsSuccess(result));
    } catch (err) {
      console.log('add content block error', err);
      yield put(actions.getWifiCampaignsError(err));
    }
  });
}

/**
  * Create a wifi campaign
  * @param: Name
  * @param: startAt
  * @param: endAt
  * @return: campaign object
  */
export function* createWifiCampaign() {
  yield takeLatest(actions.CREATE_WIFI_CAMPAIGN, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/wifi-campaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(action.data)
      });
      console.log('created campaign', result);
      yield put(actions.createWifiCampaignSuccess(result));
    } catch (err) {
      console.log('create campaign error', err);
      yield put(actions.createWifiCampaignError(err));
    }
  });
}

/**
  * Update a wifi campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* updateWifiCampaign() {
  yield takeLatest(actions.UPDATE_WIFI_CAMPAIGN, function*(action) {
    console.log('updateCampaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    const { campaign } = action;
    const { id } = campaign;
    const newCamp = Object.assign({}, campaign);
    delete newCamp.id;
    console.log('update campaign id', id);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/wifi-campaign/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(newCamp)
      });
      console.log('updated wifi campaign', result);
      message.success('Wifi Campaign updated');
      yield put(actions.updateWifiCampaignSuccess(result));
    } catch (err) {
      console.log('update wifi campaign error', err);
      yield put(actions.updateWifiCampaignError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCurrentWifiCampaigns),
    fork(getWifiCampaigns),
    fork(createWifiCampaign),
    fork(updateWifiCampaign),
  ]);
}
