import actions from './actions';

const initState = {
  templates: [],
  loading: false,
  error: '',
};

export default function campaignReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_TEMPLATES:
    case actions.CREATE_TEMPLATE:
    case actions.DELETE_TEMPLATE:
    case actions.UPDATE_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actions.GET_TEMPLATES_ERROR:
    case actions.CREATE_TEMPLATE_ERROR:
    case actions.DELETE_TEMPLATE_ERROR:
    case actions.UPDATE_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.GET_TEMPLATES_SUCCESS:
      const { templates, skip } = action;
      return {
        ...state,
        templates: skip === 0 ? templates : [...state.templates, ...templates],
        loading: false,
        error: '',
      };
    case actions.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: [...state.templates, action.template],
        loading: false,
        error: '',
      };
    case actions.UPDATE_TEMPLATE_SUCCESS: {
      const { templates } = state;
      const newTemplates = templates.map(t => {
        if (t._id === action.template._id) return action.template;
        return t;
      });
      return {
        ...state,
        templates: newTemplates,
        loading: false,
        error: ''
      };
    }
    case actions.DELETE_TEMPLATE_SUCCESS: {
      const { templates } = state;
      return {
        ...state,
        templates: templates.filter(t => t._id !== action.template._id),
        loading: false,
        error: ''
      };
    }
    default:
      return state;
  }
}
