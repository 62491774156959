// import update from 'immutability-helper';
import actions from './actions';

const initState = {
  selectedPage: null,
  pages: [],
  createdPage: null,
  isCreating: false,
  createError: false,
  loading: false,
  error: '',
  pageToken: null,
  tokenError: '',
  menus: [],
  selectedMenu: null,
  items: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.CREATE_PAGE:
    case actions.CREATE_USER_PAGE:
      return {
        ...state,
        createdPage: null,
        isCreating: true,
        createError: false,
        loading: true,
        error: '',
      };
    case actions.CREATE_PAGE_SUCCESS: {
      const { pages } = state;
      return {
        ...state,
        pages: [...pages, action.page],
        createdPage: action.page,
        isCreating: false,
        createError: false,
        loading: false,
        error: '',
      };
    }
    case actions.CREATE_PAGE_ERROR:
      return {
        ...state,
        createdPage: null,
        isCreating: false,
        createError: true,
        loading: false,
        error: action.error,
      };
    case actions.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        pages: action.pages,
        loading: false,
        error: '',
      };
    }
    case actions.SELECT_PAGE: {
      return {
        ...state,
        selectedPage: action.page,
      };
    }
    case actions.UPDATE_SELECTED_PAGE:
    case actions.DELETE_PAGE:
    case actions.UPDATE_USER_PAGE:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actions.UPDATE_SELECTED_PAGE_SUCCESS: {
      const { pages } = state;
      const newPages = pages.map(p => {
        if (p.eid === action.page.eid) {
          const newPage = { ...action.page };

          if (!newPage.PageSubscription && p.PageSubscription) {
            newPage.PageSubscription = p.PageSubscription;
          }

          if (!newPage.UserPages && p.UserPages) {
            newPage.UserPages = p.UserPages;
          }

          return newPage;
        }

        return p;
      });
      return {
        ...state,
        selectedPage: action.page,
        pages: newPages,
        loading: false,
        error: '',
      }
    }
    case actions.UPDATE_SELECTED_PAGE_ERROR:
    case actions.UPDATE_USER_PAGE_ERROR:
    case actions.DELETE_PAGE_ERROR:
    case actions.DELETE_USER_PAGE_ERROR:
    case actions.CREATE_USER_PAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOAD_PAGE_TOKEN:
      return {
        ...state,
        pageTokenError: '',
      };
    case actions.LOAD_PAGE_TOKEN_SUCCESS:
      return {
        ...state,
        pageToken: action.token,
        tokenError: '',
      };
    case actions.LOAD_PAGE_TOKEN_ERROR:
      return {
        ...state,
        pageToken: null,
        tokenError: action.error,
        selectedPage: null,
      };
    case actions.DELETE_PAGE_SUCCESS: {
      const { pages } = state;
      return {
        ...state,
        pages: pages.filter(page => page.eid !== action.page.eid),
        selectedPage: null,
        loading: false,
        error: '',
      };
    }
    case actions.UPDATE_USER_PAGE_SUCCESS: {
      const { pages, selectedPage } = state;
      // console.log('updated userPage', action.userPage);
      const newPages = pages.map(p => {
        if (p.eid === action.userPage.page_id) {
          return {
            ...p,
            UserPages: p.UserPages.map(i => {
              if (i.uid === action.userPage.uid) return action.userPage;
              return i;
            })
          }
        }
        return p;
      });
      // console.log('newPages', newPages);

      // update selectedPage
      let newSelectedPage = {...selectedPage};
      if (selectedPage && selectedPage.eid === action.userPage.page_id) {
        newSelectedPage = newPages.filter(p => p.eid === selectedPage.eid)[0];
      }
      // console.log('newSelectedPage', newSelectedPage);

      return {
        ...state,
        selectedPage: newSelectedPage,
        pages: newPages,
        loading: false,
        error: ''
      };
    }
    case actions.DELETE_USER_PAGE_SUCCESS: {
      const { pages, selectedPage } = state;

      const newPages = pages.filter((p) => {
        if (p.eid !== action.userPage.page_id) {
          // p.UserPages = p.UserPages.filter(i => i.uid !== action.userPage.uid);
          return {
            ...p,
            UserPages: p.UserPages.filter(i => i.uid !== action.userPage.uid),
          }
        }
        return p;
      });

      // update selectedPage
      let newSelectedPage = {...selectedPage};
      if (selectedPage && selectedPage.eid === action.userPage.page_id) {
        newSelectedPage.UserPages = selectedPage.UserPages.filter(i => i.uid !== action.userPage.uid);
      }

      return {
        ...state,
        pages: newPages,
        selectedPage: newSelectedPage,
        loading: false,
        error: '',
      };
    }
    case actions.CREATE_USER_PAGE_SUCCESS: {
      const { pages, selectedPage } = state;
      const newPages = pages.map(p => {
        if (p.eid === action.userPage.page_id) {
          return {
            ...p,
            UserPages: [...p.UserPages, action.userPage ]
          };
        }
        return p;
      });
      // update selectedPage
      let newSelectedPage = {...selectedPage};
      if (selectedPage && selectedPage.eid === action.userPage.page_id) {
        newSelectedPage.UserPages = [...selectedPage.UserPages, action.userPage];
      }
      return {
        ...state,
        pages: newPages,
        selectedPage: newSelectedPage,
        loading: false,
        error: '',
      };
    }
    default:
      return state;
  }
}
