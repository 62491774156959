// import update from 'immutability-helper';

import actions from './actions';

// const findBlock = (id, blocks) => {
//   const block = blocks.filter(c => c.id === id)[0]
//   return {
//     block,
//     index: blocks.indexOf(block),
//   }
// }

const initState = {
  campaigns: [],
  total: 0,
  loading: false,
  error: '',
};

export default function campaignReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ZALO_CAMPAIGNS:
    case actions.CREATE_ZALO_CAMPAIGN:
    case actions.DELETE_ZALO_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actions.GET_ZALO_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.campaigns,
        total: action.total,
        loading: false,
        error: '',
      };
    case actions.CREATE_ZALO_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.campaign],
        loading: false,
        error: '',
      };
    case actions.GET_ZALO_CAMPAIGNS_ERROR:
    case actions.CREATE_ZALO_CAMPAIGN_ERROR:
    case actions.DELETE_ZALO_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.UPDATE_ZALO_CAMPAIGN_SUCCESS: {
      const { campaigns } = state;
      const newCampaigns = campaigns.map(camp => {
        if (camp._id === action.campaign._id) return action.campaign;
        return camp;
      });
      return {
        ...state,
        campaigns: newCampaigns,
        loading: false,
        error: ''
      };
    }
    case actions.DELETE_ZALO_CAMPAIGN_SUCCESS: {
      const { campaigns } = state;
      return {
        ...state,
        campaigns: campaigns.filter(camp => camp._id !== action.campaign._id),
        loading: false,
        error: ''
      };
    }
    default:
      return state;
  }
}
