const actions = {
  LOAD_MEDIA: 'LOAD_MEDIA',
  LOAD_MEDIA_SUCCESS: 'LOAD_MEDIA_SUCCESS',
  LOAD_MEDIA_ERROR: 'LOAD_MEDIA_ERROR',
  CREATE_FILE: 'MEDIA/CREATE_FILE',
  CREATE_FILE_SUCCESS: 'MEDIA/CREATE_FILE_SUCCESS',
  CREATE_FILE_ERROR: 'MEDIA/CREATE_FILE_ERROR',
  DELETE_FILE: 'MEDIA/DELETE_FILE',
  DELETE_FILE_SUCCESS: 'MEDIA/DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR: 'MEDIA/DELETE_FILE_ERROR',
  loadMedia: (pageId) => ({ type: actions.LOAD_MEDIA, pageId }),
  createFile: data => ({
    type: actions.CREATE_FILE,
    data,
  }),
  deleteFile: (eid) => ({
    type: actions.DELETE_FILE,
    eid,
  }),
  deleteFileSuccess: (eid) => ({
    type: actions.DELETE_FILE_SUCCESS,
    eid,
  }),
  deleteFileError: (message) => ({
    type: actions.DELETE_FILE_ERROR,
    message,
  })
};

export default actions;
