import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import mediaSagas from './media/saga';
import socialPageSagas from './socialPage/sagas';
import sidebarPageMenuSagas from './sidebarPageMenu/sagas';
import pageBodySagas from './pageBody/sagas';
import campaignSagas from './campaign/saga';
// import wifiMethodSagas from './wifiMethod/saga';
import deviceSagas from './device/sagas';
import wifiCampaignSagas from './wifiCampaign/saga';
import guestCustomProfilesSagas from './guestCustomProfile/saga';
import saleLeadSagas from './saleLead/saga';
import beeTemplateSagas from './beeTemplate/saga';
import zaloSagas from './zalo/saga';
import zaloCampaignSagas from './zaloCampaign/saga';
import taskSagas from './task/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    socialPageSagas(),
    mediaSagas(),
    sidebarPageMenuSagas(),
    pageBodySagas(),
    campaignSagas(),
    // wifiMethodSagas(),
    deviceSagas(),
    wifiCampaignSagas(),
    guestCustomProfilesSagas(),
    saleLeadSagas(),
    beeTemplateSagas(),
    zaloSagas(),
    zaloCampaignSagas(),
    taskSagas(),
  ]);
}
