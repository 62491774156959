import { all, fork, takeEvery, select, call, put } from 'redux-saga/effects';
import { notification } from 'antd';

import actions from './actions';
import API_ENDPOINT from '../../settings';
import { makeSelectPageToken } from '../socialPage/selector';
import request from '../../helpers/request';

/* eslint func-names: ["error", "never"] */
/* eslint-disable no-return-await */

export function* loadLeads() {
  yield takeEvery(actions.LOAD_SALE_LEAD, function*(action) {
    const pageToken = yield select(makeSelectPageToken());

    const limit = action.limit ? action.limit : 50;
    const skip = action.skip ? action.skip : 0;
    const { fromDate, toDate } = action;
    // console.log('pageToken', pageToken);
    const url = `${API_ENDPOINT.apiUrl}/leads?limit=${limit}&skip=${skip}&fromDate=${fromDate}&toDate=${toDate}`;

    try {
      const result = yield call(
        request,
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${pageToken}`,
          },
        }
      );
      // console.log('loaded leads', result);
      yield put(actions.loadSaleLeadSuccess(result, skip > 0));

    } catch (error) {
      // console.log('load lead error', error.message);
      yield put({
        type: actions.LOAD_SALE_LEAD_ERROR,
        error: error.message,
      });
    }
  });
}

export function* deleteSaleLead() {
  yield takeEvery(actions.DELETE_SALE_LEAD, function*(action) {
    const pageToken = yield select(makeSelectPageToken());

    const url = `${API_ENDPOINT.apiUrl}/leads/${action.id}`;

    try {
      const result = yield call(
        request,
        url,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${pageToken}`,
          },
        }
      );
      // console.log('deleted leads', result);
      yield put(actions.deleteSaleLeadSuccess(result._id));
      notification.success({ message: 'Deleted' })
    } catch (error) {
      // console.log('load lead error', error.message);
      yield put({
        type: actions.DELETE_SALE_LEAD_ERROR,
        error: error.message,
      });
      notification.error({ message: error.message });
    }
  });
}

export function* updateSaleLead() {
  yield takeEvery(actions.UPDATE_SALE_LEAD, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const url = `${API_ENDPOINT.apiUrl}/leads/${action.id}`;

    try {
      const result = yield call(
        request,
        url,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${pageToken}`,
          },
          body: JSON.stringify(action.data),
        }
      );
      // console.log('deleted leads', result);
      yield put(actions.updateSaleLeadSuccess(result));
      notification.success({ message: 'Updated' })
    } catch (error) {
      // console.log('load lead error', error.message);
      yield put({
        type: actions.UPDATE_SALE_LEAD_ERROR,
        error: error.message,
      });
      notification.error({ message: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadLeads),
    fork(deleteSaleLead),
    fork(updateSaleLead),
  ]);
}
