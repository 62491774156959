const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_WITH_GOOGLE: 'LOGIN_WITH_GOOGLE',
  UPDATE_USER_PROFILE_SUCCESS: 'APP/USER/UPDATE_USER_PROFILE_SUCCESS',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: credential => ({
    type: actions.LOGIN_REQUEST,
    payload: { ...credential },
  }),
  loginSuccess: (data) => ({
    type: actions.LOGIN_SUCCESS,
    ...data,
  }),
  logout: () => ({
    type: actions.LOGOUT
  }),
  loginWithGoogle: tokenId => ({
    type: actions.LOGIN_WITH_GOOGLE,
    tokenId,
  }),
  updateUserProfileSuccess: profile => ({
    type: actions.UPDATE_USER_PROFILE_SUCCESS,
    profile,
  })
};
export default actions;
