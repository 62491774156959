const actions = {
  GET_ZALO_CAMPAIGNS: 'CAMPAIGN/GET_ZALO_CAMPAIGNS',
  GET_ZALO_CAMPAIGNS_SUCCESS: 'CAMPAIGN/GET_ZALO_CAMPAIGNS_SUCCESS',
  GET_ZALO_CAMPAIGNS_ERROR: 'CAMPAIGN/GET_ZALO_CAMPAIGNS_ERROR',
  CREATE_ZALO_CAMPAIGN: 'CAMPAIGN/CREATE_ZALO_CAMPAIGN',
  CREATE_ZALO_CAMPAIGN_SUCCESS: 'CAMPAIGN/CREATE_ZALO_CAMPAIGN_SUCCESS',
  CREATE_ZALO_CAMPAIGN_ERROR: 'CAMPAIGN/CREATE_ZALO_CAMPAIGN_ERROR',
  UPDATE_ZALO_CAMPAIGN: 'CAMPAIGN/UPDATE_ZALO_CAMPAIGN',
  UPDATE_ZALO_CAMPAIGN_SUCCESS: 'CAMPAIGN/UPDATE_ZALO_CAMPAIGN_SUCCESS',
  UPDATE_ZALO_CAMPAIGN_ERROR: 'CAMPAIGN/UPDATE_ZALO_CAMPAIGN_ERROR',
  DELETE_ZALO_CAMPAIGN: 'CAMPAIGN/DELETE_ZALO_CAMPAIGN',
  DELETE_ZALO_CAMPAIGN_SUCCESS: 'CAMPAIGN/DELETE_ZALO_CAMPAIGN_SUCCESS',
  DELETE_ZALO_CAMPAIGN_ERROR: 'CAMPAIGN/DELETE_ZALO_CAMPAIGN_ERROR',
  getZaloCampaigns: (limit = 50, skip = 0) => ({
    type: actions.GET_ZALO_CAMPAIGNS,
    limit,
    skip
  }),
  getZaloCampaignsSuccess: (campaigns, total) => ({
    type: actions.GET_ZALO_CAMPAIGNS_SUCCESS,
    campaigns,
    total,
  }),
  getZaloCampaignsError: (error) => ({
    type: actions.GET_ZALO_CAMPAIGNS_ERROR,
    error,
  }),
  createZaloCampaign: (data) => ({
    type: actions.CREATE_ZALO_CAMPAIGN,
    data,
  }),
  createZaloCampaignSuccess: (campaign) => ({
    type: actions.CREATE_ZALO_CAMPAIGN_SUCCESS,
    campaign,
  }),
  createZaloCampaignError: (error) => ({
    type: actions.CREATE_ZALO_CAMPAIGN_ERROR,
    error,
  }),
  updateZaloCampaign: (id, data) => ({
    type: actions.UPDATE_ZALO_CAMPAIGN,
    id,
    data,
  }),
  updateZaloCampaignSuccess: (campaign) => ({
    type: actions.UPDATE_ZALO_CAMPAIGN_SUCCESS,
    campaign,
  }),
  updateZaloCampaignError: (error) => ({
    type: actions.UPDATE_ZALO_CAMPAIGN_ERROR,
    error,
  }),
  deleteZaloCampaign: (id) => ({
    type: actions.DELETE_ZALO_CAMPAIGN,
    id,
  }),
  deleteZaloCampaignSuccess: (campaign) => ({
    type: actions.DELETE_ZALO_CAMPAIGN_SUCCESS,
    campaign,
  }),
  deleteZaloCampaignError: (error) => ({
    type: actions.DELETE_ZALO_CAMPAIGN_ERROR,
    error,
  }),
};

export default actions;
