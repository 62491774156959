const actions = {
  GET_ZALOMESSAGES: 'ZALO/GET_ZALOMESSAGES',
  GET_ZALOMESSAGES_SUCCESS: 'ZALO/GET_ZALOMESSAGES_SUCCESS',
  GET_ZALOMESSAGES_ERROR: 'ZALO/GET_ZALOMESSAGES_ERROR',
  CREATE_ZALOMESSAGE: 'ZALO/CREATE_ZALOMESSAGE',
  CREATE_ZALOMESSAGE_SUCCESS: 'ZALO/CREATE_ZALOMESSAGE_SUCCESS',
  CREATE_ZALOMESSAGE_ERROR: 'ZALO/CREATE_ZALOMESSAGE_ERROR',
  UPDATE_ZALOMESSAGE: 'ZALO/UPDATE_ZALOMESSAGE',
  UPDATE_ZALOMESSAGE_SUCCESS: 'ZALO/UPDATE_ZALOMESSAGE_SUCCESS',
  UPDATE_ZALOMESSAGE_ERROR: 'ZALO/UPDATE_ZALOMESSAGE_ERROR',
  DELETE_ZALOMESSAGE: 'ZALO/DELETE_ZALOMESSAGE',
  DELETE_ZALOMESSAGE_SUCCESS: 'ZALO/DELETE_ZALOMESSAGE_SUCCESS',
  DELETE_ZALOMESSAGE_ERROR: 'ZALO/DELETE_ZALOMESSAGE_ERROR',
  GET_AISETTINGS: 'ZALO/GET_AISETTINGS',
  GET_AISETTINGS_SUCCESS: 'ZALO/GET_AISETTINGS_SUCCESS',
  GET_AISETTINGS_ERROR: 'ZALO/GET_AISETTINGS_ERROR',
  CREATE_AISETTING: 'ZALO/CREATE_AISETTING',
  CREATE_AISETTING_SUCCESS: 'ZALO/CREATE_AISETTING_SUCCESS',
  CREATE_AISETTING_ERROR: 'ZALO/CREATE_AISETTING_ERROR',
  UPDATE_AISETTING: 'ZALO/UPDATE_AISETTING',
  UPDATE_AISETTING_SUCCESS: 'ZALO/UPDATE_AISETTING_SUCCESS',
  UPDATE_AISETTING_ERROR: 'ZALO/UPDATE_AISETTING_ERROR',
  DELETE_AISETTING: 'ZALO/DELETE_AISETTING',
  DELETE_AISETTING_SUCCESS: 'ZALO/DELETE_AISETTING_SUCCESS',
  DELETE_AISETTING_ERROR: 'ZALO/DELETE_AISETTING_ERROR',
  GET_PAGE_OA_INFO: 'ZALO/GET_PAGE_OA_INFO',
  GET_PAGE_OA_INFO_SUCCESS: 'ZALO/GET_PAGE_OA_INFO_SUCCESS',
  GET_PAGE_OA_INFO_ERROR: 'ZALO/GET_PAGE_OA_INFO_ERROR',
  GET_FOLLOWERS: 'ZALO/GET_FOLLOWERS',
  GET_FOLLOWERS_SUCCESS: 'ZALO/GET_FOLLOWERS_SUCCESS',
  GET_FOLLOWERS_ERROR: 'ZALO/GET_FOLLOWERS_ERROR',
  getZaloMessages: (limit = 50, skip = 0) => ({
    type: actions.GET_ZALOMESSAGES,
    limit,
    skip,
  }),
  getZaloMessagesSuccess: (zaloMessages, total) => ({
    type: actions.GET_ZALOMESSAGES_SUCCESS,
    zaloMessages,
    total,
  }),
  getZaloMessagesError: (error) => ({
    type: actions.GET_ZALOMESSAGES_ERROR,
    error,
  }),
  createZaloMessage: (data) => ({
    type: actions.CREATE_ZALOMESSAGE,
    data,
  }),
  createZaloMessageSuccess: (zaloMessage) => ({
    type: actions.CREATE_ZALOMESSAGE_SUCCESS,
    zaloMessage,
  }),
  createZaloMessageError: (error) => ({
    type: actions.CREATE_ZALOMESSAGE_ERROR,
    error,
  }),
  updateZaloMessage: (id, data) => ({
    type: actions.UPDATE_ZALOMESSAGE,
    id,
    data,
  }),
  updateZaloMessageSuccess: (zaloMessage) => ({
    type: actions.UPDATE_ZALOMESSAGE_SUCCESS,
    zaloMessage,
  }),
  updateZaloMessageError: (error) => ({
    type: actions.UPDATE_ZALOMESSAGE_ERROR,
    error,
  }),
  deleteZaloMessage: (id) => ({
    type: actions.DELETE_ZALOMESSAGE,
    id,
  }),
  deleteZaloMessageSuccess: (zaloMessage) => ({
    type: actions.DELETE_ZALOMESSAGE_SUCCESS,
    zaloMessage,
  }),
  deleteZaloMessageError: (error) => ({
    type: actions.DELETE_ZALOMESSAGE_ERROR,
    error,
  }),
  getAiSettings: (limit = 50, skip = 0) => ({
    type: actions.GET_AISETTINGS,
    limit,
    skip
  }),
  getAiSettingsSuccess: (aiSettingRules, skip) => ({
    type: actions.GET_AISETTINGS_SUCCESS,
    aiSettingRules,
    skip,
  }),
  getAiSettingsError: (error) => ({
    type: actions.GET_AISETTINGS_ERROR,
    error,
  }),
  createAiSetting: (data) => ({
    type: actions.CREATE_AISETTING,
    data,
  }),
  createAiSettingSuccess: (aiSettingRule) => ({
    type: actions.CREATE_AISETTING_SUCCESS,
    aiSettingRule,
  }),
  createAiSettingError: (error) => ({
    type: actions.CREATE_AISETTING_ERROR,
    error,
  }),
  updateAiSetting: (id, data) => ({
    type: actions.UPDATE_AISETTING,
    id,
    data,
  }),
  updateAiSettingSuccess: (aiSettingRule) => ({
    type: actions.UPDATE_AISETTING_SUCCESS,
    aiSettingRule,
  }),
  updateAiSettingError: (error) => ({
    type: actions.UPDATE_AISETTING_ERROR,
    error,
  }),
  deleteAiSetting: (id) => ({
    type: actions.DELETE_AISETTING,
    id,
  }),
  deleteAiSettingSuccess: (aiSettingRule) => ({
    type: actions.DELETE_AISETTING_SUCCESS,
    aiSettingRule,
  }),
  deleteAiSettingError: (error) => ({
    type: actions.DELETE_AISETTING_ERROR,
    error,
  }),
  getPageOaInfo: () => ({
    type: actions.GET_PAGE_OA_INFO
  }),
  getPageOaInfoSuccess: (pageOA) => ({
    type: actions.GET_PAGE_OA_INFO_SUCCESS,
    pageOA,
  }),
  getPageOaInfoError: (error) => ({
    type: actions.GET_PAGE_OA_INFO_ERROR,
    error
  }),
  getFollowers: (limit, skip, tag_name) => ({
    type: actions.GET_FOLLOWERS,
    limit,
    skip,
    tag_name
  }),
  getFollowersSuccess: (followers, totalFollowers) => ({
    type: actions.GET_FOLLOWERS_SUCCESS,
    followers,
    totalFollowers,
  }),
  getFollowersError: (error) => ({
    type: actions.GET_FOLLOWERS_ERROR,
    error,
  })
};

export default actions;
