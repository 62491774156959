import actions from './actions';

const initState = {
  files: [],
  uploadedFiles: [],
  error: false,
  message: '',
  loading: false,
};

export default function mediaReducer(state = initState, action) {
  switch (action.type) {
    case actions.DELETE_FILE:
    case actions.LOAD_MEDIA:
    case actions.CREATE_FILE:
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      };
    case actions.LOAD_MEDIA_SUCCESS:
      return {
        ...state,
        files: action.files,
        error: false,
        message: '',
        loading: false,
      };
    case actions.LOAD_MEDIA_ERROR:
    case actions.DELETE_FILE_ERROR:
    case actions.CREATE_FILE_ERROR:
      return {
        ...state,
        error: true,
        message: action.message,
        loading: false,
      };
    case actions.CREATE_FILE_SUCCESS: {
      const { files } = state;
      const { file } = action;
      return {
        ...state,
        loading: false,
        error: false,
        message: '',
        files: [ file, ...files ],
      };
    }
    case actions.DELETE_FILE_SUCCESS: {
      const { files } = state;
      return {
        ...state,
        loading: false,
        error: false,
        message: '',
        files: files.filter(f => f.eid !== action.eid)
      };
    }
    default:
      return state;
  }
}
