const actions = {
  GET_CAMPAIGNS: 'CAMPAIGN/GET_CAMPAIGNS',
  GET_CAMPAIGNS_SUCCESS: 'CAMPAIGN/GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_ERROR: 'CAMPAIGN/GET_CAMPAIGNS_ERROR',
  CREATE_CAMPAIGN: 'CAMPAIGN/CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_SUCCESS: 'CAMPAIGN/CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_ERROR: 'CAMPAIGN/CREATE_CAMPAIGN_ERROR',
  UPDATE_CAMPAIGN: 'CAMPAIGN/UPDATE_CAMPAIGN',
  UPDATE_CAMPAIGN_SUCCESS: 'CAMPAIGN/UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_ERROR: 'CAMPAIGN/UPDATE_CAMPAIGN_ERROR',
  DELETE_CAMPAIGN: 'CAMPAIGN/DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_SUCCESS: 'CAMPAIGN/DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_ERROR: 'CAMPAIGN/DELETE_CAMPAIGN_ERROR',
  SET_SELECTED_BLOCK: 'CAMPAIGN/SET_SELECTED_BLOCK',
  MOVE_CONTENT_BLOCK: 'CAMPAIGN/MOVE_CONTENT_BLOCK',
  REMOVE_CONTENT_BLOCK: 'CAMPAIGN/REMOVE_CONTENT_BLOCK',
  REMOVE_CONTENT_BLOCK_SUCCESS: 'CAMPAIGN/REMOVE_CONTENT_BLOCK_SUCCESS',
  ADD_CONTENT_BLOCK: 'CAMPAIGN/ADD_CONTENT_BLOCK',
  UPDATE_CONTENT_BLOCK: 'CAMPAIGN/UPDATE_CONTENT_BLOCK',
  SAVE_CONTENT_BLOCK: 'CAMPAIGN/SAVE_CONTENT_BLOCK',
  SAVE_CONTENT_BLOCK_SUCCESS: 'CAMPAIGN/SAVE_CONTENT_BLOCK_SUCCESS',
  SAVE_CONTENT_BLOCK_ERROR: 'CAMPAIGN/SAVE_CONTENT_BLOCK_ERROR',
  getCampaigns: () => ({
    type: actions.GET_CAMPAIGNS,
  }),
  getCampaignsSuccess: (campaigns) => ({
    type: actions.GET_CAMPAIGNS_SUCCESS,
    campaigns,
  }),
  getCampaignsError: (error) => ({
    type: actions.GET_CAMPAIGNS_ERROR,
    error,
  }),
  createCampaign: (data) => ({
    type: actions.CREATE_CAMPAIGN,
    data,
  }),
  createCampaignSuccess: (campaign) => ({
    type: actions.CREATE_CAMPAIGN_SUCCESS,
    campaign,
  }),
  createCampaignError: (error) => ({
    type: actions.CREATE_CAMPAIGN_ERROR,
    error,
  }),
  updateCampaign: (campaign) => ({
    type: actions.UPDATE_CAMPAIGN,
    campaign,
  }),
  updateCampaignSuccess: (campaign) => ({
    type: actions.UPDATE_CAMPAIGN_SUCCESS,
    campaign,
  }),
  updateCampaignError: (error) => ({
    type: actions.UPDATE_CAMPAIGN_ERROR,
    error,
  }),
  deleteCampaign: (id) => ({
    type: actions.DELETE_CAMPAIGN,
    id,
  }),
  deleteCampaignSuccess: (campaign) => ({
    type: actions.DELETE_CAMPAIGN_SUCCESS,
    campaign,
  }),
};

export default actions;
