const actions = {
  GET_CURRENT_WIFI_CAMPAIGNS: 'WIFI_CAMPAIGN/GET_CURRENT_CAMPAIGNS',
  GET_CURRENT_WIFI_CAMPAIGNS_SUCCESS: 'WIFI_CAMPAIGN/GET_CURRENT_CAMPAIGNS_SUCCESS',
  GET_CURRENT_WIFI_CAMPAIGNS_ERROR: 'WIFI_CAMPAIGN/GET_CURRENT_CAMPAIGNS_ERROR',

  GET_WIFI_CAMPAIGNS: 'WIFI_CAMPAIGN/GET_CAMPAIGNS',
  GET_WIFI_CAMPAIGNS_SUCCESS: 'WIFI_CAMPAIGN/GET_CAMPAIGNS_SUCCESS',
  GET_WIFI_CAMPAIGNS_ERROR: 'WIFI_CAMPAIGN/GET_CAMPAIGNS_ERROR',

  CREATE_WIFI_CAMPAIGN: 'WIFI_CAMPAIGN/CREATE_CAMPAIGN',
  CREATE_WIFI_CAMPAIGN_SUCCESS: 'WIFI_CAMPAIGN/CREATE_CAMPAIGN_SUCCESS',
  CREATE_WIFI_CAMPAIGN_ERROR: 'WIFI_CAMPAIGN/CREATE_CAMPAIGN_ERROR',

  UPDATE_WIFI_CAMPAIGN: 'WIFI_CAMPAIGN/UPDATE_CAMPAIGN',
  UPDATE_WIFI_CAMPAIGN_SUCCESS: 'WIFI_CAMPAIGN/UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_WIFI_CAMPAIGN_ERROR: 'WIFI_CAMPAIGN/UPDATE_CAMPAIGN_ERROR',

  DELETE_WIFI_CAMPAIGN: 'WIFI_CAMPAIGN/DELETE_CAMPAIGN',
  DELETE_WIFI_CAMPAIGN_SUCCESS: 'WIFI_CAMPAIGN/DELETE_CAMPAIGN_SUCCESS',
  DELETE_WIFI_CAMPAIGN_ERROR: 'WIFI_CAMPAIGN/DELETE_CAMPAIGN_ERROR',

  getCurrentWifiCampaigns: (pageId) => ({
    type: actions.GET_CURRENT_WIFI_CAMPAIGNS,
    pageId
  }),
  getCurrentWifiCampaignsSuccess: (campaign) => ({
    type: actions.GET_CURRENT_WIFI_CAMPAIGNS_SUCCESS,
    campaign,
  }),
  getCurrentWifiCampaignsError: (error) => ({
    type: actions.GET_CURRENT_WIFI_CAMPAIGNS_ERROR,
    error,
  }),
  getWifiCampaigns: () => ({
    type: actions.GET_WIFI_CAMPAIGNS,
  }),
  getWifiCampaignsSuccess: (campaigns) => ({
    type: actions.GET_WIFI_CAMPAIGNS_SUCCESS,
    campaigns,
  }),
  getWifiCampaignsError: (error) => ({
    type: actions.GET_WIFI_CAMPAIGNS_ERROR,
    error,
  }),
  createWifiCampaign: (data) => ({
    type: actions.CREATE_WIFI_CAMPAIGN,
    data,
  }),
  createWifiCampaignSuccess: (campaign) => ({
    type: actions.CREATE_WIFI_CAMPAIGN_SUCCESS,
    campaign,
  }),
  createWifiCampaignError: (error) => ({
    type: actions.CREATE_WIFI_CAMPAIGN_ERROR,
    error,
  }),
  updateWifiCampaign: (campaign) => ({
    type: actions.UPDATE_WIFI_CAMPAIGN,
    campaign,
  }),
  updateWifiCampaignSuccess: (campaign) => ({
    type: actions.UPDATE_WIFI_CAMPAIGN_SUCCESS,
    campaign,
  }),
  updateWifiCampaignError: (error) => ({
    type: actions.UPDATE_WIFI_CAMPAIGN_ERROR,
    error,
  }),
  deleteWifiCampaign: (id) => ({
    type: actions.DELETE_WIFI_CAMPAIGN,
    id,
  }),
  deleteWifiCampaignSuccess: (campaign) => ({
    type: actions.DELETE_WIFI_CAMPAIGN_SUCCESS,
    campaign,
  }),
};

export default actions;
