import actions from './actions';

const initState = {
  id: null,
  idToken: null,
  username: null,
  displayName: null,
  group_id: null,
  error: false,
  message: '',
  email_mobile: null,
  profile: null,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        id: action.id,
        idToken: action.token,
        username: action.username,
        email_mobile: action.email_mobile,
        displayName: action.displayName,
        group_id: action.group_id,
        error: false,
        message: '',
        profile: action.profile,
      };
    case actions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: true,
        message: action.message,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
