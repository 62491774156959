import { notification } from 'antd';
import { all, fork, takeLatest, select, call, put } from 'redux-saga/effects';
import actions from './actions';
import API_ENDPOINT from '../../settings';
// import { makeSelectToken } from '../auth/selector';
import { makeSelectPageToken, makeSelectSelectedPage } from '../socialPage/selector';
// import { makeSelectZaloAccessToken } from './selector';
import request from '../../helpers/request';

/* eslint-disable no-return-await */

export function* getZaloMessages() {
  yield takeLatest(actions.GET_ZALOMESSAGES, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const url = `${API_ENDPOINT.apiUrl}/zalo-message-templates?limit=${action.limit}&skip=${action.skip}`;
    try {
      const result = yield call(request, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('loaded campaigns', result);
      if (result.error === 0) {
        yield put(actions.getZaloMessagesSuccess(result.data.data, result.data.total));
      } else {
        yield put(actions.getZaloMessagesError(result.message));
      }
    } catch (err) {
      // console.log('add content block error', err);
      yield put(actions.getZaloMessagesError(err.message));
    }
  });
}

/**
  * Create a Message Template
  * @param: Name
  * @param: startAt
  * @param: endAt
  * @return: campaign object
  */
export function* createZaloMessage() {
  yield takeLatest(actions.CREATE_ZALOMESSAGE, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const url = `${API_ENDPOINT.apiUrl}/zalo-message-templates`;
    try {
      const result = yield call(request, url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(action.data)
      });
      // console.log('created campaign', result);
      notification.success({ message: 'Created' });
      yield put(actions.createZaloMessageSuccess(result));
    } catch (err) {
      // console.log('create campaign error', err);
      notification.error({ message: `Create failed: ${err.message}` });
      yield put(actions.createZaloMessageError(err.message));
    }
  });
}

/**
  * Update a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* updateZaloMessage() {
  yield takeLatest(actions.UPDATE_ZALOMESSAGE, function*(action) {
    // console.log('updateCampaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    const { id, data } = action;
    // console.log('update campaign id', id, newCamp);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo-message-templates/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      // console.log('updated campaign', result);
      notification.success({ message: 'Updated' });
      yield put(actions.updateZaloMessageSuccess(result));
    } catch (err) {
      // console.log('update campaign error', err);
      notification.error({ message: `Update failed: ${err.message}` });
      yield put(actions.updateZaloMessageError(err.message));
    }
  });
}

/**
  * Update a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* deleteZaloMessage() {
  yield takeLatest(actions.DELETE_ZALOMESSAGE, function*(action) {
    // console.log('delete campaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo-message-templates/${action.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('delete campaign success', result);
      notification.success({ message: 'Deleted' });
      yield put(actions.deleteZaloMessageSuccess(result));
    } catch (err) {
      // console.log('delete campaign error', err);
      notification.error({ message: `Delete failed: ${err.message}` });
      yield put(actions.deleteZaloMessageError(err.message));
    }
  });
}

export function* getAiSettings() {
  yield takeLatest(actions.GET_AISETTINGS, function*(action) {
    const selectedPage = yield select(makeSelectSelectedPage());
    const url = `${API_ENDPOINT.apiUrl}/zalo-ai-settings?pageId=${selectedPage.eid}&limit=${action.limit}&skip=${action.skip}`;
    try {
      const result = yield call(request, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('loaded campaigns', result);
      yield put(actions.getAiSettingsSuccess(result, action.skip));
    } catch (err) {
      // console.log('add content block error', err);
      yield put(actions.getAiSettingsError(err.message));
    }
  });
}

export function* createAiSetting() {
  yield takeLatest(actions.CREATE_AISETTING, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const url = `${API_ENDPOINT.apiUrl}/zalo-ai-settings`;
    try {
      const result = yield call(request, url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(action.data)
      });
      // console.log('created campaign', result);
      notification.success({ message: 'Created' });
      yield put(actions.createAiSettingSuccess(result));
    } catch (err) {
      // console.log('create campaign error', err);
      notification.error({ message: `Create failed: ${err.message}` });
      yield put(actions.createAiSettingError(err.message));
    }
  });
}

export function* getPageOaInfo() {
  yield takeLatest(actions.GET_PAGE_OA_INFO, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const url = `${API_ENDPOINT.apiUrl}/page-oa`;
    try {
      const result = yield call(request, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('loaded campaigns', result);
      yield put(actions.getPageOaInfoSuccess(result.length ? result[0]: null));
    } catch (err) {
      // console.log('add content block error', err);
      yield put(actions.getPageOaInfoError(err.message));
    }
  });
}

export function* updateAiSetting() {
  yield takeLatest(actions.UPDATE_AISETTING, function*(action) {
    // console.log('updateCampaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    const { id, data } = action;
    // console.log('update campaign id', id, newCamp);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo-ai-settings/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      // console.log('updated aiSetting', result);
      notification.success({ message: 'Updated' });
      yield put(actions.updateAiSettingSuccess(result));
    } catch (err) {
      // console.log('update campaign error', err);
      notification.error({ message: `Update failed: ${err.message}` });
      yield put(actions.updateAiSettingError(err.message));
    }
  });
}

export function* deleteAiSetting() {
  yield takeLatest(actions.DELETE_AISETTING, function*(action) {
    // console.log('delete campaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo-ai-settings/${action.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('delete campaign success', result);
      notification.success({ message: 'Deleted' });
      yield put(actions.deleteAiSettingSuccess(result));
    } catch (err) {
      // console.log('delete campaign error', err);
      notification.error({ message: `Delete failed: ${err.message}` });
      yield put(actions.deleteAiSettingError(err.message));
    }
  });
}

export function* getFollowers() {
  yield takeLatest(actions.GET_FOLLOWERS, function*(action) {
    // const accessToken = yield select(makeSelectZaloAccessToken());
    // console.log('accessToken', accessToken);
    const pageToken = yield select(makeSelectPageToken());
    const { skip, limit } = action;
    const url = `${API_ENDPOINT.apiUrl}/zalo/users?size=${limit}&from=${skip}`;
    try {
      const result = yield call(request, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`
        }
      });
      // console.log('loaded followers', result);
      if (result && result.message === 'Success') {
        yield put(actions.getFollowersSuccess(result.data.hits, result.data.total.value));
      } else {
        throw new Error(result.message);
      }
    } catch (err) {
      console.log('getFollowersError', err);
      notification.error({ message: `Get followers error: ${err.message}` });
      yield put(actions.getFollowersError(err.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getZaloMessages),
    fork(createZaloMessage),
    fork(updateZaloMessage),
    fork(deleteZaloMessage),
    fork(getAiSettings),
    fork(createAiSetting),
    fork(updateAiSetting),
    fork(deleteAiSetting),
    fork(getPageOaInfo),
    fork(getFollowers),
  ]);
}
