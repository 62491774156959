import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import userpic from '../../image/user1.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { Menu, Icon } from 'antd';

import pageActions from '../../redux/socialPage/actions';

const { logout } = authAction;
const { selectPage } = pageActions;
const { SubMenu } = Menu;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      showAllPages: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const { user, pages, selectPage, selectedPage } = this.props;
    const { showAllPages } = this.state;
    // console.log('pages', pages);
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {user && (<strong>{user.displayName}</strong>)}
        <Menu
          onClick={(e) => {
            if (e.key === "toggleShow") {
              this.setState({ showAllPages: !this.state.showAllPages });
            }
            if (e.item.props.page) selectPage(e.item.props.page);
          }}
          style={{ width: '100%' }}
          defaultSelectedKeys={selectedPage ? [selectedPage.eid] : []}
          defaultOpenKeys={['pages']}
          mode="inline"
        >
          <SubMenu
            key="pages"
            title={
              <span>
                <Icon type="layout" />
                <span><IntlMessages id="topUser.your_pages" />:</span>
              </span>
            }
          >
            {showAllPages || pages.length < 5
              ? pages.map(p => p && (
                <Menu.Item key={p.eid} page={p}>{p.name}</Menu.Item>
              ))
              : pages.map((p, index) => {
                if (index < 5) {
                  return <Menu.Item key={p.eid} page={p}>{p.name}</Menu.Item>;
                }
                return null;
              })
            }
            {pages.length >= 5 && (
              <Menu.Item key="toggleShow" style={{ color: '#1890ff'}}>
                {showAllPages ? 'show less...' : 'show more...'}
              </Menu.Item>
            )}
          </SubMenu>
        </Menu>
        <Link to='/dashboard/create-page' className="isoDropdownLink">
          <IntlMessages id="sidebar.createPage" />
        </Link>
        <Link to='/dashboard/settings' className="isoDropdownLink">
          <IntlMessages id="themeSwitcher.settings" />
        </Link>
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={userpic} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default connect(
  state => ({
    ...state.SocialPage,
  }),
  { logout, selectPage }
)(TopbarUser);
