import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';

import actions from './actions';
import API_ENDPOINT from '../../settings';
import { makeSelectPageToken, makeSelectSelectedPage } from '../socialPage/selector';
import { makeSelectSelectedPagemenu } from '../sidebarPageMenu/selector';
import request from '../../helpers/request';

export function* loadPageItems() {
  yield takeLatest(actions.LOAD_PAGE_ITEMS, function* (action){
    const selectedPage = yield select(makeSelectSelectedPage());
    const selectedPageMenu = yield select(makeSelectSelectedPagemenu());
    // console.log('selectedPageMenu in saga', selectedPageMenu);
    const limit = action.limit || 50;
    const skip = action.skip || 0;
    const url = selectedPageMenu === null
      ? `${API_ENDPOINT.apiUrl}/page-item?limit=${limit}&skip=${skip}&eid=${selectedPage.eid}`
      : `${API_ENDPOINT.apiUrl}/page-item?limit=${limit}&skip=${skip}&eid=${selectedPage.eid}&menu=${selectedPageMenu}`;
    try {
      const result = yield call(request, url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        //  Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('load page item result', result, skip);
      yield put(actions.loadPageItemsSuccess(result.rows, skip));
    } catch (err) {
      // console.log('load page item error', err);
      yield put(actions.loadPageItemsError(err));
    }
  });
}

/**
  * Function handle create page item
  */
export function* createPageItem() {
  yield takeLatest(actions.CREATE_PAGE_ITEM, function* (action){
    const pageToken = yield select(makeSelectPageToken());

    const data = Object.assign({}, action.data);
    data.media_files = JSON.stringify(action.data.media_files);
    // console.log('data to create page item:', data);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/page-item`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      // console.log('created item', result);
      yield put(actions.createPageItemSuccess(result));
      notification.success({ message: 'Created'});
    } catch (err) {
      // console.log('create page item error', err);
      yield put(actions.createPageItemError(err));
      notification.error({ message: 'Faile to create'});
    }
  });
}

/**
  * Function that handle edit Page Item
  */
export function* editPageItem() {
  yield takeLatest(actions.EDIT_PAGE_ITEM, function* (action) {
    const pageToken = yield select(makeSelectPageToken());
    const data = Object.assign({}, action.data);
    data.media_files = JSON.stringify(action.data.media_files);
    // console.log('data to edit page item:', data);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/page-item/${action.eid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      // console.log('edit item', result);
      yield put(actions.editPageItemSuccess(result));
      notification.success({ message: 'Updated'});
    } catch (err) {
      // console.log('edit page item error', err);
      yield put(actions.editPageItemError(err));
      notification.error({ message: 'Faile to update'});
    }
  });
}

/**
  * Delete a page Item
  */
export function* deletePageItem() {
  yield takeLatest(actions.DELETE_PAGE_ITEM, function* (action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/page-item/${action.eid}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('deleted item:', result);
      yield put(actions.deletePageItemSuccess(result));
      notification.success({ message: 'Deleted'});
    } catch (err) {
      // console.log('delete page item error', err);
      yield put(actions.deletePageItemError(err));
      notification.error({ message: 'Failed to delete'});
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(loadPageItems),
    fork(createPageItem),
    fork(editPageItem),
    fork(deletePageItem),
  ]);
}
