const actions ={
  LOAD_PAGE_MENU: 'SidebarPageMenu/LOAD_PAGE_MENU',
  LOAD_PAGE_MENU_SUCCESS: 'SidebarPageMenu/LOAD_PAGE_MENU_SUCCESS',
  LOAD_PAGE_MENU_ERROR: 'SidebarPageMenu/LOAD_PAGE_MENU_ERROR',
  CREATE_PAGE_MENU: 'SidebarPageMenu/CREATE_PAGE_MENU',
  CREATE_PAGE_MENU_SUCCESS: 'SidebarPageMenu/CREATE_PAGE_MENU_SUCCESS',
  CREATE_PAGE_MENU_ERROR: 'SidebarPageMenu/CREATE_PAGE_MENU_ERROR',
  UPDATE_PAGE_MENU: 'SidebarPageMenu/UPDATE_PAGE_MENU',
  UPDATE_PAGE_MENU_SUCCESS: 'SidebarPageMenu/UPDATE_PAGE_MENU_SUCCESS',
  UPDATE_PAGE_MENU_ERROR: 'SidebarPageMenu/UPDATE_PAGE_MENU_ERROR',
  DELETE_PAGE_MENU: 'SidebarPageMenu/DELETE_PAGE_MENU',
  DELETE_PAGE_MENU_SUCCESS: 'SidebarPageMenu/DELETE_PAGE_MENU_SUCCESS',
  DELETE_PAGE_MENU_ERROR: 'SidebarPageMenu/DELETE_PAGE_MENU_ERROR',
  SELECT_PAGE_MENU: 'SidebarPageMenu/SELECT_PAGE_MENU',
  loadPageMenu: () => ({
    type: actions.LOAD_PAGE_MENU
  }),
  loadPageMenuSuccess: (menus) => ({
    type: actions.LOAD_PAGE_MENU_SUCCESS,
    menus,
  }),
  loadPageMenuError: (error) => ({
    type: actions.LOAD_PAGE_MENU_ERROR,
    error,
  }),
  createPageMenu: (menu) => ({
    type: actions.CREATE_PAGE_MENU,
    menu,
  }),
  createPageMenuSuccess: (menu) => ({
    type: actions.CREATE_PAGE_MENU_SUCCESS,
    menu,
  }),
  createPageMenuError: (error) => ({
    type: actions.CREATE_PAGE_MENU_ERROR,
    error,
  }),
  updatePageMenu: (menu) => ({
    type: actions.UPDATE_PAGE_MENU,
    menu
  }),
  updatePageMenuSuccess: (menu) => ({
    type: actions.UPDATE_PAGE_MENU_SUCCESS,
    menu
  }),
  updatePageMenuError: (error) => ({
    type: actions.UPDATE_PAGE_MENU_ERROR,
    error
  }),
  deletePageMenu: (menu) => ({
    type: actions.DELETE_PAGE_MENU,
    menu
  }),
  deletePageMenuSuccess: (menu) => ({
    type: actions.DELETE_PAGE_MENU_SUCCESS,
    menu
  }),
  deletePageMenuError: (error) => ({
    type: actions.DELETE_PAGE_MENU_ERROR,
    error
  }),
  selectPageMenu: (menu) => ({
    type: actions.SELECT_PAGE_MENU,
    menu,
  }),
}

export default actions;
