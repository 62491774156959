import { all, fork, takeEvery, put, call, select } from 'redux-saga/effects';
import { message, notification } from 'antd';
import actions from './actions';
import API_ENDPOINT from '../../settings';
import makeSelectAuth, { makeSelectToken } from '../auth/selector';
// import { makeSelectPageToken } from './selector';
import request from '../../helpers/request';
import * as authActions from '../auth/actions';

// console.log('authActions', authActions.default);
/* eslint func-names: ["error", "never"] */
export function* createPage() {
  yield takeEvery(actions.CREATE_PAGE, function*(action) {
    const token = yield select(makeSelectToken());

    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/pages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(action.data),
      });
      // console.log('create result', result);
      yield put(actions.createPageSuccess(result));
    } catch (err) {
      console.log('error rrr', err.message);
      yield put(actions.createPageError(err.message));

    }
  });
}

export function* loadPages() {
  yield takeEvery(actions.LOAD_PAGE, function*() {
    const token = yield select(makeSelectToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/pages`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('load pages result', result);
      yield put(actions.loadPagesSuccess(result.rows));
    } catch (err) {
      console.log('load pages error', err);
      yield put(actions.loadPagesError(err));
    }
  });
}

/**
 * Load pages after login success
 */
export function* loadPagesAfterLogin() {
  yield takeEvery(authActions.default.LOGIN_SUCCESS, function*() {
    yield put(actions.loadPages());
  });
}

/**
 * Saga runs after selectPage: Load page token after select a page
 */
export function* loadPageToken() {
  yield takeEvery(actions.SELECT_PAGE, function*(action) {
    const authData = yield select(makeSelectAuth());
    const token = authData.idToken;
    const data = {
      pageId: action.page.eid,
      uid: authData.id,
    }
    try {
      const pageToken = yield call(request, `${API_ENDPOINT.apiUrl}/auth/page`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      yield put(actions.loadPageTokenSuccess(pageToken));
    } catch (err) {
      yield put(actions.loadPageTokenError(err.message));
    }
  })
}

/**
  * Saga that take UPDATE_SELECTED_PAGE
  */
export function* updateSelectedPage() {
  yield takeEvery(actions.UPDATE_SELECTED_PAGE, function* (action) {
    const token = yield select(makeSelectToken());
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(action.data),
    };
    const url = `${API_ENDPOINT.apiUrl}/pages/${action.eid}`;
    try {
      const result = yield call(request, url, options);
      // console.log('update page result', result);
      yield put(actions.updateSelectedPageSuccess(result));
    } catch (err) {
      console.log('update page error', err);
      yield put(actions.updateSelectedPageError(err));
    }
  });
}

/**
  * Delete a page
  */
export function* deletePage() {
  yield takeEvery(actions.DELETE_PAGE, function* (action) {
    const token = yield select(makeSelectToken());
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${API_ENDPOINT.apiUrl}/pages/${action.eid}`;
    try {
      const result = yield call(request, url, options);
      // console.log('delete page success result', result);
      yield put(actions.deletePageSuccess(result));
      message.success('Deleted');
    } catch (err) {
      console.log('delete page error', err);
      // message.error(err.message);
      yield call(notification.error, { message: err.message });
      yield put(actions.deletePageError(err.message));
    }
  });
}

/**
  * Saga that take UPDATE_USER_PAGE
  */
export function* updateUserPage() {
  yield takeEvery(actions.UPDATE_USER_PAGE, function* (action) {
    const token = yield select(makeSelectToken());
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(action.data),
    };
    const url = `${API_ENDPOINT.apiUrl}/user-page`;
    try {
      const result = yield call(request, url, options);
      // console.log('update user page result', result);
      yield put(actions.updateUserPageSuccess(result));
      message.success('Updated');
    } catch (err) {
      // console.log('update user page error:', err.message);
      yield put(actions.updateUserPageError(err.message));
      message.error(err.message);
    }
  });
}

/**
  * Saga that take DELETE_USER_PAGE
  */
export function* deleteUserPage() {
  yield takeEvery(actions.DELETE_USER_PAGE, function* (action) {
    const token = yield select(makeSelectToken());
    console.log('action', action);
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ page_id: action.pageId, uid: action.uid }),
    };
    const url = `${API_ENDPOINT.apiUrl}/user-page`;
    try {
      const result = yield call(request, url, options);
      // console.log('deleted user page result', result);
      yield put(actions.deleteUserPageSuccess(result));
      message.success('Deleted');
    } catch (err) {
      yield put(actions.deleteUserPageError(err.message));
      message.error(err.message);
    }
  });
}

/**
  * Saga that take CREATE_USER_PAGE
  */
export function* createUserPage() {
  yield takeEvery(actions.CREATE_USER_PAGE, function* (action) {
    const token = yield select(makeSelectToken());
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(action.data),
    };
    const url = `${API_ENDPOINT.apiUrl}/user-page`;
    try {
      const result = yield call(request, url, options);
      // console.log('create user page result', result);
      yield put(actions.createUserPageSuccess(result));
      message.success('Created');
    } catch (err) {
      console.log('create user page error:', err.message);
      yield put(actions.createUserPageError(err.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createPage),
    fork(loadPages),
    fork(loadPagesAfterLogin),
    fork(loadPageToken),
    fork(updateSelectedPage),
    fork(deletePage),
    fork(updateUserPage),
    fork(deleteUserPage),
    fork(createUserPage),
  ]);
}
