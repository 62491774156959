import { notification } from 'antd';
import { all, fork, takeLatest, select, call, put } from 'redux-saga/effects';
import actions from './actions';
import API_ENDPOINT from '../../settings';
// import { makeSelectToken } from '../auth/selector';
import { makeSelectPageToken } from '../socialPage/selector';
import request from '../../helpers/request';
/* eslint-disable no-return-await */

export function* getTemplates() {
  yield takeLatest(actions.GET_TEMPLATES, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const { templateType, category, limit, skip } = action;

    let queryString = `type=${templateType || 'shared'}`;
    if (category) {
      queryString += `&category=${category}`;
    }

    queryString += `&limit=${limit || 50}`;
    queryString += `&skip=${skip || 0}`;

    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/bee-templates?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('loaded templates', result);
      yield put(actions.getTemplatesSuccess(result, skip));
    } catch (err) {
      // console.log('add content block error', err);
      yield put(actions.getTemplatesError(err.message));
    }
  });
}

/**
  * Create a campaign
  * @param: Name
  * @param: startAt
  * @param: endAt
  * @return: campaign object
  */
export function* createTemplate() {
  yield takeLatest(actions.CREATE_TEMPLATE, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/bee-templates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(action.data)
      });
      // console.log('created campaign', result);
      notification.success({ message: 'Template created' });
      yield put(actions.createTemplateSuccess(result));
    } catch (err) {
      // console.log('create campaign error', err);
      notification.error({ message: 'Template create failed' });
      yield put(actions.createTemplateError(err.message));
    }
  });
}

/**
  * Update a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* updateTemplate() {
  yield takeLatest(actions.UPDATE_TEMPLATE, function*(action) {
    // console.log('updateCampaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    const { id, data } = action;
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/bee-templates/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      // console.log('updated campaign', result);
      notification.success({ message: 'Template updated' });
      yield put(actions.updateTemplateSuccess(result));
    } catch (err) {
      // console.log('update campaign error', err);
      notification.error({ message: 'Update template failed!' });
      yield put(actions.updateTemplateError(err.message));
    }
  });
}

/**
  * Update a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* deleteTemplate() {
  yield takeLatest(actions.DELETE_TEMPLATE, function*(action) {
    // console.log('delete campaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/bee-templates/${action.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('delete campaign success', result);
      yield put(actions.deleteTemplateSuccess(result));
    } catch (err) {
      // console.log('delete campaign error', err);
      yield put(actions.deleteTemplateError(err.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTemplates),
    fork(createTemplate),
    fork(updateTemplate),
    fork(deleteTemplate),
  ]);
}
