const actions = {
  LOAD_CUSTOM_PROFILE: 'LOAD_CUSTOM_PROFILE',
  LOAD_CUSTOM_PROFILE_SUCCESS: 'LOAD_CUSTOM_PROFILE_SUCCESS',
  LOAD_CUSTOM_PROFILE_ERROR: 'LOAD_CUSTOM_PROFILE_ERROR',
  loadCustomProfiles: ({ limit, skip, fromDate, toDate }) => ({
    type: actions.LOAD_CUSTOM_PROFILE,
    limit,
    skip,
    fromDate,
    toDate,
  }),
  loadCustomProfilesSuccess: (data, loadMore = false) => ({
    type: actions.LOAD_CUSTOM_PROFILE_SUCCESS,
    data,
    loadMore,
  }),
  loadCustomProfilesError: (error) => ({
    type: actions.LOAD_CUSTOM_PROFILE_ERROR,
    error,
  })
};

export default actions;
