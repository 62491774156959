import { notification } from 'antd';
import { all, fork, takeLatest, select, call, put } from 'redux-saga/effects';
import actions from './actions';
import API_ENDPOINT from '../../settings';
// import { makeSelectToken } from '../auth/selector';
import { makeSelectPageToken } from '../socialPage/selector';
import request from '../../helpers/request';
// import { DefaultBlockValue } from '../../containers/Campaign/constants';

/* eslint-disable no-return-await */

export function* getZaloCampaigns() {
  yield takeLatest(actions.GET_ZALO_CAMPAIGNS, function*(action) {
    const { limit, skip } = action;
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo/campaigns?limit=${limit}&skip=${skip}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('loaded campaigns', result);
      if (result && result.error === 0) {
        yield put(actions.getZaloCampaignsSuccess(result.data.data, result.data.total));
      } else if (result) {
        notification.error({ message: result.message });
        throw new Error(result.message);
      }
    } catch (err) {
      // console.log('add content block error', err);
      notification.error({ message: err.message });
      yield put(actions.getZaloCampaignsError(err.message));
    }
  });
}

/**
  * Create a campaign
  * @param: Name
  * @param: startAt
  * @param: endAt
  * @return: campaign object
  */
export function* createZaloCampaign() {
  yield takeLatest(actions.CREATE_ZALO_CAMPAIGN, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo/campaigns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(action.data)
      });
      // console.log('created campaign', result);
      if (result && result.error === 0) {
        notification.success({ message: 'Campaign created' });
        yield put(actions.createZaloCampaignSuccess(result.data));
      } else {
        const msg = result.message || 'Unknown error';
        notification.error({ message: msg });
        yield put(actions.createZaloCampaignError(msg));
      }
    } catch (err) {
      // console.log('create campaign error', err);
      notification.error({ message: err.message });
      yield put(actions.createZaloCampaignError(err.message));
    }
  });
}

/**
  * Update a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* updateZaloCampaign() {
  yield takeLatest(actions.UPDATE_ZALO_CAMPAIGN, function*(action) {
    // console.log('updateCampaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    const { id, data } = action;
    // console.log('update campaign id', id, newCamp);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo/campaigns/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      // console.log('updated campaign', result);
      if (result && result.error === 0) {
        notification.success({ message: 'Campaign updated' });
        yield put(actions.updateZaloCampaignSuccess(result.data));
      } else {
        const msg = result.message || 'Unknown error';
        notification.error({ message: msg });
        yield put(actions.updateZaloCampaignError(msg));
      }
    } catch (err) {
      // console.log('update campaign error', err);
      notification.error({ message: `Update campaign failed: ${err.message}` });
      yield put(actions.updateZaloCampaignError(err.message));
    }
  });
}

/**
  * Delete a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* deleteZaloCampaign() {
  yield takeLatest(actions.DELETE_ZALO_CAMPAIGN, function*(action) {
    // console.log('delete campaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/zalo/campaigns/${action.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      if (result && result.error === 0) {
        notification.success({ message: 'Campaign deleted' });
        yield put(actions.deleteZaloCampaignSuccess(result.data));
      } else {
        const msg = result.message || 'Unknown error';
        notification.error({ message: msg });
        yield put(actions.deleteZaloCampaignError(msg));
      }
    } catch (err) {
      // console.log('delete campaign error', err.message);
      notification.error({ message: `Delete campaign failed: ${err.message}` });
      yield put(actions.deleteZaloCampaignError(err.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getZaloCampaigns),
    fork(createZaloCampaign),
    fork(updateZaloCampaign),
    fork(deleteZaloCampaign),
  ]);
}
