import { createSelector } from 'reselect';

const selectSocialPageDomain = state => state.SocialPage;
// const selectSplashPageDomain = state => state.get('splashPage', initialState);

const makeSelectSocialPage = () =>
  createSelector(selectSocialPageDomain, substate => substate);

const makeSelectSelectedPage = () =>
  createSelector(selectSocialPageDomain, homeState => homeState.selectedPage);

const makeSelectPageToken = () =>
  createSelector(selectSocialPageDomain, homeState => homeState.pageToken);

const makeSelectPages = () =>
  createSelector(selectSocialPageDomain, homeState => homeState.pages);

export default makeSelectSocialPage;
export { makeSelectPages, makeSelectSelectedPage, makeSelectPageToken };
