import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { message } from 'antd';

import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import config from '../../settings';
import request from '../../helpers/request';

// const fakeApiCall = true; // auth0 or express JWT
const API_ENDPOINT = config.apiUrl;

const onLoginRequest = async (email_mobile, password) =>
  await fetch(`${API_ENDPOINT}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ email_mobile, password }),
  })
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      message.error(`Login Error: ${error.message}`);
      return error;
    });

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function*({ payload }) {
    const { email_mobile, password } = payload;
    try {
      const loginResult = yield call(onLoginRequest, email_mobile, password);
      // console.log('loginResult', loginResult);
      if (loginResult && loginResult.token) {
        // console.log('loginResult', loginResult);
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: loginResult.token,
          id: loginResult.data.id,
          username: loginResult.data.username,
          group_id: loginResult.data.group_id,
          displayName: loginResult.data.displayName,
          email_mobile: loginResult.data.email_mobile,
          profile: loginResult.profile,
        });
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          message: `Login Error: ${loginResult.message}`,
        });
        message.error(`Login Error: ${loginResult.message}`);
      }
    } catch (error) {
      // yield put(actions.youtubeSearchSuccess());
      yield put({
        type: actions.LOGIN_ERROR,
        message: error.message,
      });
      message.error(`Login Error: ${error.message}`);
      // console.log(error);
    }
  });
}

/**
  Hanlde login with Google
  */
export function* loginWithGoogle() {
  yield takeEvery(actions.LOGIN_WITH_GOOGLE, function*(action) {
    try {
      const loginResult = yield call(request, `${API_ENDPOINT}/auth/login-with-google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tokenId: action.tokenId })
      });
      // console.log('loaded campaigns', loginResult);
      if (loginResult && loginResult.token) {
        // console.log('loginResult', loginResult);
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: loginResult.token,
          id: loginResult.data.id,
          username: loginResult.data.username,
          group_id: loginResult.data.group_id,
          displayName: loginResult.data.displayName,
          email_mobile: loginResult.data.email_mobile,
          profile: loginResult.profile,
        });
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          message: `Login Error: ${loginResult.message}`,
        });
        message.error(`Login Error: ${loginResult.message}`);
      }
    } catch (error) {
      // console.log('add content block error', err);
      yield put({
        type: actions.LOGIN_ERROR,
        message: error.message,
      });
      message.error(`Login Error: ${error.message}`);
    }
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    // console.log('payload in login succes', payload);
    yield localStorage.setItem('id_token', payload.token);
    yield localStorage.setItem('id', payload.id);
    yield localStorage.setItem('username', payload.username);
    yield localStorage.setItem('displayName', payload.displayName);
    yield localStorage.setItem('group_id', payload.group_id);
    yield localStorage.setItem('email_mobile', payload.email_mobile);
    yield localStorage.setItem('profile', JSON.stringify(payload.profile));
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const authMap = getToken();
    const id = authMap.get('id');
    const token = authMap.get('idToken');
    const username = authMap.get('username');
    const displayName = authMap.get('displayName');
    const group_id = authMap.get('group_id');
    const email_mobile = authMap.get('email_mobile');
    const profile = authMap.get('profile');
    // console.log('authMap', authMap);
    // console.log('data in localStorage', id, token, username, displayName);
    if (!token) return;
    try {
      const result = yield call(request, `${API_ENDPOINT}/auth/verify-id-token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('verify result', result);
      if (token && result) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          id,
          token,
          username,
          displayName,
          group_id: result.data ? result.data.group_id : group_id,
          email_mobile,
          profile,
        });
      }
    } catch (err) {
      console.log('verify error', err);
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(loginWithGoogle),
  ]);
}
