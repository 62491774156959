const actions = {
  LOAD_SALE_LEAD: 'WIFIBIZ/LOAD_SALE_LEAD',
  LOAD_SALE_LEAD_SUCCESS: 'WIFIBIZ/LOAD_SALE_LEAD_SUCCESS',
  LOAD_SALE_LEAD_ERROR: 'WIFIBIZ/LOAD_SALE_LEAD_ERROR',
  UPDATE_SALE_LEAD: 'WIFIBIZ/UPDATE_SALE_LEAD',
  UPDATE_SALE_LEAD_SUCCESS: 'WIFIBIZ/UPDATE_SALE_LEAD_SUCCESS',
  UPDATE_SALE_LEAD_ERROR: 'WIFIBIZ/UPDATE_SALE_LEAD_ERROR',
  DELETE_SALE_LEAD: 'WIFIBIZ/DELETE_SALE_LEAD',
  DELETE_SALE_LEAD_SUCCESS: 'WIFIBIZ/DELETE_SALE_LEAD_SUCCESS',
  DELETE_SALE_LEAD_ERROR: 'WIFIBIZ/DELETE_SALE_LEAD_ERROR',
  loadSaleLead: ({ limit, skip, fromDate, toDate }) => ({
    type: actions.LOAD_SALE_LEAD,
    limit,
    skip,
    fromDate,
    toDate,
  }),
  loadSaleLeadSuccess: (data, loadMore = false) => ({
    type: actions.LOAD_SALE_LEAD_SUCCESS,
    data,
    loadMore,
  }),
  loadSaleLeadError: (error) => ({
    type: actions.LOAD_SALE_LEAD_ERROR,
    error,
  }),
  deleteSaleLead: (id) => ({
    type: actions.DELETE_SALE_LEAD,
    id,
  }),
  deleteSaleLeadSuccess: (id) => ({
    type: actions.DELETE_SALE_LEAD_SUCCESS,
    id,
  }),
  deleteSaleLeadError: (error) => ({
    type: actions.DELETE_SALE_LEAD_ERROR,
    error,
  }),
  updateSaleLead: (id, data) => ({
    type: actions.UPDATE_SALE_LEAD,
    id,
    data,
  }),
  updateSaleLeadSuccess: lead => ({
    type: actions.UPDATE_SALE_LEAD_SUCCESS,
    lead,
  }),
  updateSaleLeadError: error => ({
    type: actions.UPDATE_SALE_LEAD_ERROR,
    error,
  })
};

export default actions;
