import { all, fork, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import actions from './actions';
import API_ENDPOINT from '../../settings';
import { makeSelectPageToken, makeSelectSelectedPage } from '../socialPage/selector';
import request from '../../helpers/request';
import * as socialPageActions from '../socialPage/actions';

// console.log('authActions', authActions.default);
/* eslint func-names: ["error", "never"] */

export function* loadPageMenu() {
  yield takeEvery(actions.LOAD_PAGE_MENU, function*() {
    const selectedPage = yield select(makeSelectSelectedPage());
    // console.log('selectedPage in sagas', selectedPage);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/page-menu?eid=${selectedPage.eid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // console.log('load page menu result', result);
      yield put(actions.loadPageMenuSuccess(result.rows));
    } catch (err) {
      // console.log('load page menu error', err);
      yield put(actions.loadPageMenuError(err));
    }
  });
}

/**
 * Load page menu after load pageToken success
 */
export function* loadPageMenuAfterLoadPageToken() {
 yield takeEvery(socialPageActions.default.LOAD_PAGE_TOKEN_SUCCESS, function*() {
   yield put(actions.loadPageMenu());
 });
}

/**
 * Create a page menu
 */
export function* createPageMenu() {
  yield takeEvery(actions.CREATE_PAGE_MENU, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const data = {
      name: action.menu.name,
      is_default: action.menu.is_default,
    };
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/page-menu`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      console.log('created menu', result);
      yield put(actions.createPageMenuSuccess(result));
    } catch (err) {
      console.log('create page menu error', err);
      yield put(actions.createPageMenuError(err));
    }
  });
}

/**
 * Update a page menu
 */
export function* updatePageMenu() {
  yield takeLatest(actions.UPDATE_PAGE_MENU, function*(action) {
    // console.log('update with action', action);
    const pageToken = yield select(makeSelectPageToken());
    const data = {
      name: action.menu.name,
      is_default: action.menu.is_default,
    };
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/page-menu/${action.menu.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(data)
      });
      // console.log('updated menu', result);
      message.success('Updated');
      yield put(actions.updatePageMenuSuccess(result));
    } catch (err) {
      // console.log('update page menu error', err);
      message.error(err.message);
      yield put(actions.updatePageMenuError(err.message));
    }
  });
}

/**
 * Delete a page menu
 */
export function* deletePageMenu() {
  yield takeLatest(actions.DELETE_PAGE_MENU, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/page-menu/${action.menu.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('delete menu', result);
      yield put(actions.deletePageMenuSuccess(result));
      message.success('Deleted');
    } catch (err) {
      // console.log('delete page menu error', err);
      message.error(err.message);
      yield put(actions.deletePageMenuError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadPageMenu),
    fork(loadPageMenuAfterLoadPageToken),
    fork(createPageMenu),
    fork(updatePageMenu),
    fork(deletePageMenu),
  ]);
}
