import Auth from './auth/reducer';
import App from './app/reducer';
import SocialPage from './socialPage/reducers';
import Media from './media/reducer';
import PageMenu from './sidebarPageMenu/reducers';
import PageBody from './pageBody/reducers';
import Campaign from './campaign/reducer';
// import WifiMethod from './wifiMethod/reducer';
import Device from './device/reducers';
import WifiCampaign from './wifiCampaign/reducer';
import GuestCustomProfile from './guestCustomProfile/reducer';
import SaleLead from './saleLead/reducer';
import BeeTemplate from './beeTemplate/reducer';
import Zalo from './zalo/reducer';
import ZaloCampaign from './zaloCampaign/reducer';
import Task from './task/reducer';

export default {
  Auth,
  App,
  SocialPage,
  PageMenu,
  Media,
  PageBody,
  Campaign,
  // WifiMethod,
  Device,
  WifiCampaign,
  GuestCustomProfile,
  SaleLead,
  BeeTemplate,
  Zalo,
  ZaloCampaign,
  Task,
};
