import { createSelector } from 'reselect';

const selectAuthDomain = state => state.Auth;
// const selectSplashPageDomain = state => state.get('splashPage', initialState);

const makeSelectAuth = () =>
  createSelector(selectAuthDomain, substate => substate);

const makeSelectToken = () =>
  createSelector(selectAuthDomain, homeState => homeState.idToken);

export default makeSelectAuth;
export { makeSelectToken };
