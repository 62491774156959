import { notification } from 'antd';
import { all, fork, takeLatest, select, call, put } from 'redux-saga/effects';
import actions from './actions';
import API_ENDPOINT from '../../settings';
// import { makeSelectToken } from '../auth/selector';
import { makeSelectPageToken } from '../socialPage/selector';
import request from '../../helpers/request';
// import { DefaultBlockValue } from '../../containers/Campaign/constants';

/* eslint-disable no-return-await */

export function* getCampaigns() {
  yield takeLatest(actions.GET_CAMPAIGNS, function*() {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/campaigns`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      // console.log('loaded campaigns', result);
      yield put(actions.getCampaignsSuccess(result));
    } catch (err) {
      // console.log('add content block error', err);
      yield put(actions.getCampaignsError(err));
    }
  });
}

/**
  * Create a campaign
  * @param: Name
  * @param: startAt
  * @param: endAt
  * @return: campaign object
  */
export function* createCampaign() {
  yield takeLatest(actions.CREATE_CAMPAIGN, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/campaigns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(action.data)
      });
      // console.log('created campaign', result);
      yield put(actions.createCampaignSuccess(result));
    } catch (err) {
      // console.log('create campaign error', err);
      yield put(actions.createCampaignError(err));
    }
  });
}

/**
  * Update a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* updateCampaign() {
  yield takeLatest(actions.UPDATE_CAMPAIGN, function*(action) {
    // console.log('updateCampaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    const { campaign } = action;
    const { id } = campaign;
    const newCamp = Object.assign({}, campaign);
    delete newCamp.id;
    delete newCamp._id;
    delete newCamp.__v;
    // console.log('update campaign id', id, newCamp);
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/campaigns/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(newCamp)
      });
      // console.log('updated campaign', result);
      notification.success({ message: 'Campaign updated' });
      yield put(actions.updateCampaignSuccess(result));
    } catch (err) {
      // console.log('update campaign error', err);
      notification.error({ message: 'Update campaign failed!' });
      yield put(actions.updateCampaignError(err));
    }
  });
}

/**
  * Update a campaign
  * @param: campaign Object
  * @return: campaign object
  */
export function* deleteCampaign() {
  yield takeLatest(actions.DELETE_CAMPAIGN, function*(action) {
    // console.log('delete campaign with action:', action);
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/campaigns/${action.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
      });
      console.log('delete campaign success', result);
      yield put(actions.deleteCampaignSuccess(result));
    } catch (err) {
      console.log('delete campaign error', err);
      yield put(actions.deleteCampaignError(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCampaigns),
    fork(createCampaign),
    fork(updateCampaign),
    fork(deleteCampaign),
  ]);
}
