import actions from './actions';

const initState = {
  followers: [],
  totalFollowers: 0,
  pageOA: null,
  zaloMessages: [],
  totalZaloMessages: 0,
  aiSettingRules: [],
  loading: false,
  error: '',
};

export default function zaloReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ZALOMESSAGES:
    case actions.CREATE_ZALOMESSAGE:
    case actions.UPDATE_ZALOMESSAGE:
    case actions.DELETE_ZALOMESSAGE:
    case actions.GET_AISETTINGS:
    case actions.CREATE_AISETTING:
    case actions.UPDATE_AISETTING:
    case actions.DELETE_AISETTING:
    case actions.GET_PAGE_OA_INFO:
    case actions.GET_FOLLOWERS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actions.GET_ZALOMESSAGES_ERROR:
    case actions.CREATE_ZALOMESSAGE_ERROR:
    case actions.UPDATE_ZALOMESSAGE_ERROR:
    case actions.DELETE_ZALOMESSAGE_ERROR:
    case actions.GET_AISETTINGS_ERROR:
    case actions.CREATE_AISETTING_ERROR:
    case actions.UPDATE_AISETTING_ERROR:
    case actions.DELETE_AISETTING_ERROR:
    case actions.GET_PAGE_OA_INFO_ERROR:
    case actions.GET_FOLLOWERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.GET_ZALOMESSAGES_SUCCESS:
      return {
        ...state,
        zaloMessages: action.zaloMessages,
        totalZaloMessages: action.total,
        loading: false,
        error: '',
      };
    case actions.CREATE_ZALOMESSAGE_SUCCESS:
      return {
        ...state,
        zaloMessages: [...state.zaloMessages, action.zaloMessage],
        loading: false,
        error: '',
      };
    case actions.UPDATE_ZALOMESSAGE_SUCCESS: {
      const { zaloMessages } = state;
      const newZaloMessages = zaloMessages.map(z => {
        if (z._id === action.zaloMessage._id) return action.zaloMessage;
        return z;
      });
      return {
        ...state,
        zaloMessages: newZaloMessages,
        loading: false,
        error: ''
      };
    }
    case actions.DELETE_ZALOMESSAGE_SUCCESS: {
      const { zaloMessages } = state;
      return {
        ...state,
        zaloMessages: zaloMessages.filter(z => z._id !== action.zaloMessage._id),
        loading: false,
        error: ''
      };
    }
    case actions.GET_AISETTINGS_SUCCESS:
      const { aiSettingRules } = state;
      let newAiSettingRules = action.aiSettingRules;
      if (action.skip && action.skip > 0) {
        newAiSettingRules = [...aiSettingRules, ...action.aiSettingRules];
      }
      return {
        ...state,
        aiSettingRules: newAiSettingRules,
        loading: false,
        error: '',
      };
    case actions.CREATE_AISETTING_SUCCESS:
      return {
        ...state,
        aiSettingRules: [...state.aiSettingRules, action.aiSettingRule],
        loading: false,
        error: '',
      };
    case actions.UPDATE_AISETTING_SUCCESS: {
      const { aiSettingRules } = state;
      const newAiSettingRules = aiSettingRules.map(z => {
        if (z._id === action.aiSettingRule._id) return action.aiSettingRule;
        return z;
      });
      return {
        ...state,
        aiSettingRules: newAiSettingRules,
        loading: false,
        error: ''
      };
    }
    case actions.DELETE_AISETTING_SUCCESS: {
      const { aiSettingRules } = state;
      return {
        ...state,
        aiSettingRules: aiSettingRules.filter(z => z._id !== action.aiSettingRule._id),
        loading: false,
        error: ''
      };
    }
    case actions.GET_PAGE_OA_INFO_SUCCESS: {
      return {
        ...state,
        pageOA: action.pageOA,
        loading: false,
        error: ''
      };
    }
    case actions.GET_FOLLOWERS_SUCCESS: {
      // console.log('reducer', action.followers);
      return {
        ...state,
        followers: action.followers,
        totalFollowers: action.totalFollowers,
        loading: false,
        error: '',
      };
    }
    default:
      return state;
  }
}
