import actions from './actions';

const initState = {
  total: 0,
  profiles: [],
  error: '',
  loading: false,
};

export default function guestCustomProfileReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_CUSTOM_PROFILE:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case actions.LOAD_CUSTOM_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOAD_CUSTOM_PROFILE_SUCCESS: {
      // const { profiles } = state;
      const { data } = action;
      // console.log('newProfiles', newProfiles);
      return {
        ...state,
        total: data.total,
        profiles: data.profiles,
        loading: false,
        error: '',
      };
    }
    default:
      return state;
  }
}
