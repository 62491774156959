import actions from './actions';

const initState = {
  menus: [],
  selectedPageMenu: null,
  error: '',
  loading: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_PAGE_MENU_SUCCESS:
      return {
        ...state,
        menus: action.menus,
        error: '',
      };
    case actions.CREATE_PAGE_MENU:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_PAGE_MENU_SUCCESS: {
      const { menus } = state;
      return {
        ...state,
        loading: false,
        menus: [...menus, action.menu]
      };
    }
    case actions.UPDATE_PAGE_MENU_SUCCESS: {
      const { menus } = state;
      return {
        ...state,
        loading: false,
        menus: menus.map(m => {
          if (m.id === action.menu.id) return action.menu;
          return m;
        })
      };
    }
    case actions.DELETE_PAGE_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        menus: state.menus.filter(m => m.id !== action.menu.id)
      };
    case actions.CREATE_PAGE_MENU_ERROR:
    case actions.UPDATE_PAGE_MENU_ERROR:
    case actions.DELETE_PAGE_MENU_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.SELECT_PAGE_MENU:
      return {
        ...state,
        selectedPageMenu: action.menu,
      };
    default:
      return state;
  }
}
