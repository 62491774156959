import { notification } from 'antd';
import { all, fork, takeEvery, select, call, put } from 'redux-saga/effects';
import actions from './actions';
import API_ENDPOINT from '../../settings';
import { makeSelectToken } from '../auth/selector';
import request from '../../helpers/request';

/* eslint func-names: ["error", "never"] */
/* eslint-disable no-return-await */
const onLoadMedia = (token, pageId) =>
  new Promise((resolve, reject) => {
    fetch(`${API_ENDPOINT.apiUrl}/files?pageId=${pageId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        if (res.status === 200) return res.json();
        return reject(new Error(res.statusText));
      })
      .then(res => resolve(res))
      .catch(e => reject(e));
  });

const onCreateFile = (token, data) =>
  new Promise((resolve, reject) => {
    fetch(`${API_ENDPOINT.apiUrl}/files`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        if (res.status === 200) return res.json();
        return reject(new Error(res.statusText));
      })
      .then(res => resolve(res))
      .catch(e => reject(e));
  });

export function* loadMediaFiles() {
  yield takeEvery(actions.LOAD_MEDIA, function*(action) {
    const token = yield select(makeSelectToken());
    try {
      const files = yield call(onLoadMedia, token, action.pageId);

      if (files) {
        yield put({
          type: actions.LOAD_MEDIA_SUCCESS,
          files: files.rows,
        });
      }
    } catch (error) {
      // console.log('loadMedia error', error.message);
      yield put({
        type: actions.LOAD_MEDIA_ERROR,
        error: error.message,
      });
      notification.error({ message: error.message });
    }
  });
}

export function* insertFile() {
  yield takeEvery(actions.CREATE_FILE, function*(action) {
    // console.log(' action in insertFile', action);
    const token = yield select(makeSelectToken());
    const {
      data: { file, filename, url, pageId },
    } = action;
    const mimetype = file.type;
    const fileType = mimetype.split('/')[0] === 'image' ? 'i' : 'v';
    const data = {
      type: fileType,
      name: file.name,
      url,
      filename,
      mimetype,
      extension: mimetype.split('/')[1],
      filesize: file.size,
      pageId,
    };
    try {
      const insertedFile = yield call(onCreateFile, token, data);
      // console.log('insertedFile', insertedFile);
      if (insertedFile) {
        yield put({
          type: actions.CREATE_FILE_SUCCESS,
          file: insertedFile,
        });
      }
    } catch (e) {
      // console.log('loadMedia error', e.message);
      yield put({
        type: actions.CREATE_FILE_ERROR,
        error: e.message,
      });
      notification.error({ message: e.message });
    }
  });
}

export function* deleteFile() {
  yield takeEvery(actions.DELETE_FILE, function*(action) {
    // get user token
    const token = yield select(makeSelectToken());
    const url = `${API_ENDPOINT.apiUrl}/files/${action.eid}`;

    try {
      const deletedFile = yield call(request, url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('delete result', deletedFile);
      yield put(actions.deleteFileSuccess(deletedFile.eid));
      notification.success({ message: 'File deleted' });
    } catch (err) {
      // console.log('load page item error', err);
      yield put(actions.deleteFileError(err.message));
      notification.error({ message: err.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadMediaFiles),
    fork(insertFile),
    fork(deleteFile),
  ]);
}
