const actions = {
  CREATE_DEVICE: 'DEVICE/CREATE_DEVICE',
  CREATE_DEVICE_SUCCESS: 'DEVICE/CREATE_DEVICE_SUCCESS',
  CREATE_DEVICE_ERROR: 'DEVICE/CREATE_DEVICE_ERROR',
  UPDATE_DEVICE: 'DEVICE/UPDATE_DEVICE',
  UPDATE_DEVICE_SUCCESS: 'DEVICE/UPDATE_DEVICE_SUCCESS',
  UPDATE_DEVICE_ERROR: 'DEVICE/UPDATE_DEVICE_ERROR',
  DELETE_DEVICE: 'DEVICE/DELETE_DEVICE',
  DELETE_DEVICE_SUCCESS: 'DEVICE/DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_ERROR: 'DEVICE/DELETE_DEVICE_ERROR',
  LOAD_DEVICES: 'DEVICE/LOAD_DEVICES',
  LOAD_DEVICES_SUCCESS: 'DEVICE/LOAD_DEVICES_SUCCESS',
  LOAD_DEVICES_ERROR: 'DEVICE/LOAD_DEVICES_ERROR',
  CLEAR_MESSAGE: 'DEVICE/CLEAR_MESSAGE',
  clearMessage: () => ({
    type: actions.CLEAR_MESSAGE,
  }),
  createDevice: (data) => ({
    type: actions.CREATE_DEVICE,
    data,
  }),
  createDeviceSuccess: (device) => ({
    type: actions.CREATE_DEVICE_SUCCESS,
    device,
  }),
  createDeviceError: (error) => ({
    type: actions.CREATE_DEVICE_ERROR,
    error,
  }),
  updateDevice: (device) => ({
    type: actions.UPDATE_DEVICE,
    device,
  }),
  updateDeviceSuccess: (device) => ({
    type: actions.UPDATE_DEVICE_SUCCESS,
    device,
  }),
  updateDeviceError: (error) => ({
    type: actions.UPDATE_DEVICE_ERROR,
    error,
  }),
  deleteDevice: (nas_id) => ({
    type: actions.DELETE_DEVICE,
    nas_id,
  }),
  deleteDeviceSuccess: (device) => ({
    type: actions.DELETE_DEVICE_SUCCESS,
    device,
  }),
  deleteDeviceError: (error) => ({
    type: actions.DELETE_DEVICE_ERROR,
    error,
  }),
  loadDevices: (pageId) => ({
    type: actions.LOAD_DEVICES,
    pageId
  }),
  loadDevicesSuccess: (devices) => ({
    type: actions.LOAD_DEVICES_SUCCESS,
    devices,
  }),
  loadDevicesError: (error) => ({
    type: actions.LOAD_DEVICES_ERROR,
    error,
  })
};

export default actions;
