import actions from './actions';

const initState = {
  total: 0,
  tasks: [],
  error: '',
  loading: false,
};

export default function taskReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_TASK:
    case actions.DELETE_TASK:
    case actions.UPDATE_TASK:
    case actions.CREATE_TASK:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case actions.DELETE_TASK_ERROR:
    case actions.LOAD_TASK_ERROR:
    case actions.UPDATE_TASK_ERROR:
    case actions.CREATE_TASK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOAD_TASK_SUCCESS: {
      const { tasks } = state;
      const { data, loadMore } = action;
      const newTasks = loadMore
        ? [...tasks, ...data.tasks]
        : data.tasks;
      // console.log('newProfiles', newProfiles);
      return {
        ...state,
        total: data.total,
        tasks: newTasks,
        loading: false,
        error: '',
      };
    }
    case actions.DELETE_TASK_SUCCESS: {
      const { tasks, total } = state;
      return {
        ...state,
        loading: false,
        error: '',
        total: total - 1,
        tasks: tasks.filter(t => t._id !== action.id),
      };
    }
    case actions.UPDATE_TASK_SUCCESS: {
      const { tasks } = state;
      return {
        ...state,
        loading: false,
        error: '',
        tasks: tasks.map(l => {
          if (l._id === action.task._id) {
            return action.task;
          }
          return l;
        })
      };
    }
    case actions.CREATE_TASK_SUCCESS: {
      const { tasks } = state;
      return {
        ...state,
        loading: false,
        error: '',
        tasks: [action.task, ...tasks],
      };
    }
    default:
      return state;
  }
}
