const actions = {
  LOAD_TASK: 'WIFIBIZ/LOAD_TASK',
  LOAD_TASK_SUCCESS: 'WIFIBIZ/LOAD_TASK_SUCCESS',
  LOAD_TASK_ERROR: 'WIFIBIZ/LOAD_TASK_ERROR',
  CREATE_TASK: 'WIFIBIZ/CREATE_TASK',
  CREATE_TASK_SUCCESS: 'WIFIBIZ/CREATE_TASK_SUCCESS',
  CREATE_TASK_ERROR: 'WIFIBIZ/CREATE_TASK_ERROR',
  UPDATE_TASK: 'WIFIBIZ/UPDATE_TASK',
  UPDATE_TASK_SUCCESS: 'WIFIBIZ/UPDATE_TASK_SUCCESS',
  UPDATE_TASK_ERROR: 'WIFIBIZ/UPDATE_TASK_ERROR',
  DELETE_TASK: 'WIFIBIZ/DELETE_TASK',
  DELETE_TASK_SUCCESS: 'WIFIBIZ/DELETE_TASK_SUCCESS',
  DELETE_TASK_ERROR: 'WIFIBIZ/DELETE_TASK_ERROR',
  loadTask: ({ limit, skip, fromDate, toDate, pageId }) => ({
    type: actions.LOAD_TASK,
    limit,
    skip,
    fromDate,
    toDate,
    pageId,
  }),
  loadTaskSuccess: (data, loadMore = false) => ({
    type: actions.LOAD_TASK_SUCCESS,
    data,
    loadMore,
  }),
  loadTaskError: (error) => ({
    type: actions.LOAD_TASK_ERROR,
    error,
  }),
  createTask: (data) => ({
    type: actions.CREATE_TASK,
    data,
  }),
  createTaskSuccess: (task) => ({
    type: actions.CREATE_TASK_SUCCESS,
    task,
  }),
  createTaskError: (error) => ({
    type: actions.CREATE_TASK_ERROR,
    error,
  }),
  deleteTask: (id) => ({
    type: actions.DELETE_TASK,
    id,
  }),
  deleteTaskSuccess: (id) => ({
    type: actions.DELETE_TASK_SUCCESS,
    id,
  }),
  deleteTaskError: (error) => ({
    type: actions.DELETE_TASK_ERROR,
    error,
  }),
  updateTask: (id, data) => ({
    type: actions.UPDATE_TASK,
    id,
    data,
  }),
  updateTaskSuccess: task => ({
    type: actions.UPDATE_TASK_SUCCESS,
    task,
  }),
  updateTaskError: error => ({
    type: actions.UPDATE_TASK_ERROR,
    error,
  })
};

export default actions;
