import { Map } from 'immutable';
import moment from 'moment';

/** pkce functions
function dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
  }
function generateCodeVerifier() {
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}

// GENERATING CODE CHALLENGE FROM VERIFIER
function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

async function generateCodeChallengeFromVerifier(v) {
  var hashed = await sha256(v);
  var base64encoded = base64urlencode(hashed);
  return base64encoded;
}
** end of pkce functions */

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function getToken() {
  try {
    const id = localStorage.getItem('id');
    const idToken = localStorage.getItem('id_token');
    const username = localStorage.getItem('username');
    const displayName = localStorage.getItem('displayName');
    const group_id = localStorage.getItem('group_id');
    const email_mobile = localStorage.getItem('email_mobile');

    // profile
    const profileStr = localStorage.getItem('profile');
    const profile = profileStr ? JSON.parse(profileStr) : null;
    return new Map({ id, idToken, username, displayName, group_id, email_mobile, profile });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function IsJsonString(str) {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    const json = JSON.parse(str);
    return (typeof json !== 'undefined');
  } catch (e) {
    return false;
  }
}

export function isValidLicense(page) {
  if (!page || !page.PageSubscription || !page.PageSubscription.Plan) {
    return false;
  }

  if (!page.PageSubscription.isLifetime && moment(page.PageSubscription.end_date) < moment()) {
    return false;
  }

  if (page.PageSubscription.Plan.name === 'FREE') {
    return false;
  }

  return true;
}

export function isAllowUploadVideo(page) {
  if (!page || !page.PageSubscription || !page.PageSubscription.Plan) {
    return false;
  }

  if (moment(page.PageSubscription.end_date) < moment()) {
    return false;
  }

  if (page.PageSubscription.Plan.name === 'FREE') {
    return false;
  }

  const other_metrics = JSON.parse(page.PageSubscription.Plan.other_metrics);
  if (other_metrics.videoUpload === 1) {
    return true;
  }

  return false;
}

export function isAllowCreateCTA(page) {
  if (!page || !page.PageSubscription || !page.PageSubscription.Plan) {
    return false;
  }

  if (moment(page.PageSubscription.end_date) < moment()) {
    return false;
  }

  if (page.PageSubscription.Plan.name === 'FREE') {
    return false;
  }

  const other_metrics = JSON.parse(page.PageSubscription.Plan.other_metrics);
  if (other_metrics.cta === 1) {
    return true;
  }

  return false;
}

export function getDateRange(period, date) {
  let fromDate, toDate;
  let current = moment();
  switch (period) {
    case 'last7':
      toDate = current.add(1,'days').format('YYYY-MM-DD');
      fromDate = current.subtract(7,'day').format('YYYY-MM-DD');
      break;
    case 'last10':
      toDate = current.add(1,'days').format('YYYY-MM-DD');
      fromDate = current.subtract(10,'day').format('YYYY-MM-DD');
      break;
    case 'last30':
      toDate = current.add(1,'days').format('YYYY-MM-DD');
      fromDate = current.subtract(30,'day').format('YYYY-MM-DD');
      break;
    case 'range':
      fromDate = date.split(',')[0];
      toDate = date.split(',')[1] || current.format('YYYY-MM-DD');
      break;
    default:
      toDate = current.format('YYYY-MM-DD');
      fromDate = current.subtract(7,'day').format('YYYY-MM-DD');
  }
  return { fromDate, toDate };
}

export function getPageUserRole(uid, page) {
  let role;
  if (page && page.UserPages && page.UserPages.length) {
    const tmp = page.UserPages.filter(rec => rec.uid === uid);
    if (tmp.length) {
      role = tmp[0].role;
    }
  }
  return role;
}

export function getAllowedModules(page) {
  const allowed = [];
  if (page && page.PageSubscription && page.PageSubscription.Plan
      && page.PageSubscription.Plan.other_metrics
  ) {
      const otherMetrics = JSON.parse(page.PageSubscription.Plan.other_metrics);
      if (typeof otherMetrics === 'object' && otherMetrics.modules) {
          otherMetrics.modules.map(m => allowed.push(m));
      }
  }
  return allowed;
}

/**
export async function generatePKCEPair() {
  const codeVerifier = generateCodeVerifier();
  try {
    const codeChallenge = await generateCodeChallengeFromVerifier(
      codeVerifier
    );
    return {
      success: true,
      codeVerifier,
      codeChallenge
    };
  } catch (e) {
    return {
      success: false,
      error: e.message,
    };
  }
}
*/
