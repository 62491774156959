import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Dashboard')),
  },
  {
    path: 'my-pages',
    component: asyncComponent(() => import('../SocialPage/PageList')),
  },
  {
    path: 'create-page',
    component: asyncComponent(() => import('../SocialPage/createPage')),
  },
  {
    path: 'page/:eid',
    component: asyncComponent(() => import('../SocialPage')),
  },
  {
    path: 'devices',
    component: asyncComponent(() => import('../Devices')),
  },
  {
    path: 'media',
    component: asyncComponent(() => import('../Media')),
  },
  {
    path: 'campaign/:id',
    component: asyncComponent(() => import('../Campaign/CampaignDesign/index.js')),
  },
  {
    path: 'campaign',
    component: asyncComponent(() => import('../Campaign')),
  },
  {
    path: 'wifi-campaign',
    component: asyncComponent(() => import('../WifiCampaign')),
  },
  {
    path: 'wifi-campaign/:campaignId',
    component: asyncComponent(() => import('../wifiFlow')),
  },
  {
    path: 'tasks',
    component: asyncComponent(() => import('../Task')),
  },
  {
    path: 'wifi-login-flow',
    component: asyncComponent(() => import('../wifiFlow')),
  },
  {
    path: 'report-overview',
    component: asyncComponent(() => import('../Reports/wifiLoginOverview')),
  },
  {
    path: 'report-campaigns',
    component: asyncComponent(() => import('../Reports/Campaigns')),
  },
  {
    path: 'report-visits',
    component: asyncComponent(() => import('../Reports/visits')),
  },
  {
    path: 'report-devices',
    component: asyncComponent(() => import('../Reports/devices')),
  },
  {
    path: 'report-software',
    component: asyncComponent(() => import('../Reports/software')),
  },
  {
    path: 'report-times',
    component: asyncComponent(() => import('../Reports/times')),
  },
  {
    path: 'guests-custom-profile',
    component: asyncComponent(() => import('../GuestCustomProfile')),
  },
  {
    path: 'report-survey',
    component: asyncComponent(() => import('../Reports/Survey')),
  },
  {
    path: 'report-top-users',
    component: asyncComponent(() => import('../Reports/topUsers')),
  },
  {
    path: 'sale-leads',
    component: asyncComponent(() => import('../SaleLead')),
  },
  {
    path: 'admin-plans',
    component: asyncComponent(() => import('../Plan')),
  },
  {
    path: 'admin-subscriptions',
    component: asyncComponent(() => import('../PageSubscriptions')),
  },
  {
    path: 'accesscode',
    component: asyncComponent(() => import('../AccessCode')),
  },
  {
    path: 'settings',
    component: asyncComponent(() => import('../Settings')),
  },
  {
    path: 'page-notification',
    component: asyncComponent(() => import('../PageSettings/notification')),
  },
  {
    path: 'page-license',
    component: asyncComponent(() => import('../PageSettings/pageLicense')),
  },
  {
    path: 'page-role',
    component: asyncComponent(() => import('../PageSettings/pageRole')),
  },
  /*
  {
    path: 'page-zalo',
    component: asyncComponent(() => import('../PageSettings/oaPage')),
  },
  {
    path: 'page-zalo-create',
    component: asyncComponent(() => import('../PageSettings/oaPageCreate')),
  },
  */
  {
    path: 'admin-license-code',
    component: asyncComponent(() => import('../PageSubscriptions/PageLicenseCode')),
  },
  {
    path: 'page-integration',
    component: asyncComponent(() => import('../PageSettings/Integration')),
  },
  {
    path: 'admin-manage-templates',
    component: asyncComponent(() => import('../BeeTemplate')),
  },
  /*
  {
    path: 'zalo-bot',
    component: asyncComponent(() => import('../Zalo')),
  },
  {
    path: 'zalo-followers',
    component: asyncComponent(() => import('../Zalo/FollowerList')),
  },
  {
    path: 'zalo-messages',
    component: asyncComponent(() => import('../ZaloCampaign')),
  },
  {
    path: 'zalo-message-templates',
    component: asyncComponent(() => import('../Zalo/MessageTemplate/MsgTemplates')),
  },
  */
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
