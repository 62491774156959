const actions = {
  GET_TEMPLATES: 'BEE/GET_TEMPLATES',
  GET_TEMPLATES_SUCCESS: 'BEE/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_ERROR: 'BEE/GET_TEMPLATES_ERROR',
  CREATE_TEMPLATE: 'BEE/CREATE_TEMPLATE',
  CREATE_TEMPLATE_SUCCESS: 'BEE/CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_ERROR: 'BEE/CREATE_TEMPLATE_ERROR',
  UPDATE_TEMPLATE: 'BEE/UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_SUCCESS: 'BEE/UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_ERROR: 'BEE/UPDATE_TEMPLATE_ERROR',
  DELETE_TEMPLATE: 'BEE/DELETE_TEMPLATE',
  DELETE_TEMPLATE_SUCCESS: 'BEE/DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_ERROR: 'BEE/DELETE_TEMPLATE_ERROR',
  getTemplates: (templateType, category, limit, skip) => ({
    type: actions.GET_TEMPLATES,
    templateType,
    category,
    limit,
    skip
  }),
  getTemplatesSuccess: (templates, skip) => ({
    type: actions.GET_TEMPLATES_SUCCESS,
    templates,
    skip,
  }),
  getTemplatesError: (error) => ({
    type: actions.GET_TEMPLATES_ERROR,
    error,
  }),
  createTemplate: (data) => ({
    type: actions.CREATE_TEMPLATE,
    data,
  }),
  createTemplateSuccess: (template) => ({
    type: actions.CREATE_TEMPLATE_SUCCESS,
    template,
  }),
  createTemplateError: (error) => ({
    type: actions.CREATE_TEMPLATE_ERROR,
    error,
  }),
  updateTemplate: (id, data) => ({
    type: actions.UPDATE_TEMPLATE,
    id,
    data,
  }),
  updateTemplateSuccess: (template) => ({
    type: actions.UPDATE_TEMPLATE_SUCCESS,
    template,
  }),
  updateTemplateError: (error) => ({
    type: actions.UPDATE_TEMPLATE_ERROR,
    error,
  }),
  deleteTemplate: (id) => ({
    type: actions.DELETE_TEMPLATE,
    id,
  }),
  deleteTemplateSuccess: (template) => ({
    type: actions.DELETE_TEMPLATE_SUCCESS,
    template,
  }),
  deleteTemplateError: (error) => ({
    type: actions.DELETE_TEMPLATE_ERROR,
    error,
  })
};

export default actions;
