const actions = {
  LOAD_PAGE_TOKEN: 'SOCIALPAGE/LOAD_PAGE_TOKEN',
  LOAD_PAGE_TOKEN_SUCCESS: 'SOCIALPAGE/LOAD_PAGE_TOKEN_SUCCESS',
  LOAD_PAGE_TOKEN_ERROR: 'SOCIALPAGE/LOAD_PAGE_TOKEN_ERROR',
  LOAD_PAGE: 'SOCIALPAGE/LOAD_PAGE',
  LOAD_PAGE_SUCCESS: 'SOCIALPAGE/LOAD_PAGE_SUCCESS',
  LOAD_PAGE_ERROR: 'SOCIALPAGE/LOAD_PAGE_EROOR',
  SELECT_PAGE: 'SOCIALPAGE/SELECT_PAGE',
  UPDATE_SELECTED_PAGE: 'SOCIALPAGE/UPDATE_SELECTED_PAGE',
  UPDATE_SELECTED_PAGE_SUCCESS: 'SOCIALPAGE/UPDATE_SELECTED_PAGE_SUCCESS',
  UPDATE_SELECTED_PAGE_ERROR: 'SOCIALPAGE/UPDATE_SELECTED_PAGE_ERROR',
  CREATE_PAGE: 'SOCIALPAGE/CREATE_PAGE',
  CREATE_PAGE_SUCCESS: 'SOCIALPAGE/CREATE_PAGE_SUCCESS',
  CREATE_PAGE_ERROR: 'SOCIALPAGE/CREATE_PAGE_EROOR',
  DELETE_PAGE: 'SOCIALPAGE/DELETE_PAGE',
  DELETE_PAGE_SUCCESS: 'SOCIALPAGE/DELETE_PAGE_SUCCESS',
  DELETE_PAGE_ERROR: 'SOCIALPAGE/DELETE_PAGE_ERROR',
  CREATE_USER_PAGE: 'SOCIALPAGE/CREATE_USER_PAGE',
  CREATE_USER_PAGE_SUCCESS: 'SOCIALPAGE/CREATE_USER_PAGE_SUCCESS',
  CREATE_USER_PAGE_ERROR: 'SOCIALPAGE/CREATE_USER_PAGE_ERROR',
  UPDATE_USER_PAGE: 'SOCIALPAGE/UPDATE_USER_PAGE',
  UPDATE_USER_PAGE_SUCCESS: 'SOCIALPAGE/UPDATE_USER_PAGE_SUCCESS',
  UPDATE_USER_PAGE_ERROR: 'SOCIALPAGE/UPDATE_USER_PAGE_ERROR',
  DELETE_USER_PAGE: 'SOCIALPAGE/DELETE_USER_PAGE',
  DELETE_USER_PAGE_SUCCESS: 'SOCIALPAGE/DELETE_USER_PAGE_SUCCESS',
  DELETE_USER_PAGE_ERROR: 'SOCIALPAGE/DELETE_USER_PAGE_ERROR',
  createPage: data => ({
    type: actions.CREATE_PAGE,
    data,
  }),
  createPageSuccess: page => ({
    type: actions.CREATE_PAGE_SUCCESS,
    page,
  }),
  createPageError: error => ({
    type: actions.CREATE_PAGE_ERROR,
    error,
  }),
  loadPages: () => ({
    type: actions.LOAD_PAGE,
  }),
  loadPagesSuccess: pages => ({
    type: actions.LOAD_PAGE_SUCCESS,
    pages,
  }),
  loadPagesError: error => ({
    type: actions.LOAD_PAGE_EROOR,
    error,
  }),
  selectPage: page => ({
    type: actions.SELECT_PAGE,
    page,
  }),
  updateSelectedPage: (eid,data) => ({
    type: actions.UPDATE_SELECTED_PAGE,
    eid,
    data,
  }),
  updateSelectedPageSuccess: page => ({
    type: actions.UPDATE_SELECTED_PAGE_SUCCESS,
    page,
  }),
  updateSelectedPageError: error => ({
    type: actions.UPDATE_SELECTED_PAGE_ERROR,
    error,
  }),
  loadPageToken: () => ({
    type: actions.LOAD_PAGE_TOKEN,
  }),
  loadPageTokenSuccess: token => ({
    type: actions.LOAD_PAGE_TOKEN_SUCCESS,
    token,
  }),
  loadPageTokenError: error => ({
    type: actions.LOAD_PAGE_TOKEN_ERROR,
    error,
  }),
  deletePage: eid => ({
    type: actions.DELETE_PAGE,
    eid,
  }),
  deletePageSuccess: (page) => ({
    type: actions.DELETE_PAGE_SUCCESS,
    page,
  }),
  deletePageError: error => ({
    type: actions.DELETE_PAGE_ERROR,
    error,
  }),
  updateUserPage: data => ({
    type: actions.UPDATE_USER_PAGE,
    data,
  }),
  updateUserPageSuccess: userPage => ({
    type: actions.UPDATE_USER_PAGE_SUCCESS,
    userPage,
  }),
  updateUserPageError: error => ({
    type: actions.UPDATE_USER_PAGE_ERROR,
    error,
  }),
  deleteUserPage: (pageId, uid) => ({
    type: actions.DELETE_USER_PAGE,
    pageId,
    uid,
  }),
  deleteUserPageSuccess: userPage => ({
    type: actions.DELETE_USER_PAGE_SUCCESS,
    userPage,
  }),
  deleteUserPageError: error => ({
    type: actions.DELETE_USER_PAGE_ERROR,
    error,
  }),
  createUserPage: (data) => ({
    type: actions.CREATE_USER_PAGE,
    data,
  }),
  createUserPageSuccess: (userPage) => ({
    type: actions.CREATE_USER_PAGE_SUCCESS,
    userPage
  }),
  createUserPageError: (error) => ({
    type: actions.CREATE_USER_PAGE_ERROR,
    error,
  })
};
export default actions;
