import { all, fork, takeEvery, takeLatest, select, call, put } from 'redux-saga/effects';
import { notification } from 'antd';

import actions from './actions';
import API_ENDPOINT from '../../settings';
import { makeSelectPageToken } from '../socialPage/selector';
import request from '../../helpers/request';

/* eslint func-names: ["error", "never"] */
/* eslint-disable no-return-await */

export function* loadTasks() {
  yield takeLatest(actions.LOAD_TASK, function*(action) {
    // const pageToken = yield select(makeSelectPageToken());

    const limit = action.limit ? action.limit : 50;
    const skip = action.skip ? action.skip : 0;
    const { fromDate, toDate, pageId } = action;
    // console.log('pageToken', pageToken);
    const url = `${API_ENDPOINT.apiUrl}/tasks?limit=${limit}&skip=${skip}&fromDate=${fromDate}&toDate=${toDate}&pageId=${pageId}`;

    try {
      const result = yield call(
        request,
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${pageToken}`,
          },
        }
      );
      // console.log('loaded tasks', result);
      yield put(actions.loadTaskSuccess(result, skip > 0));

    } catch (error) {
      // console.log('load lead error', error.message);
      yield put({
        type: actions.LOAD_TASK_ERROR,
        error: error.message,
      });
    }
  });
}

export function* createTask() {
  yield takeLatest(actions.CREATE_TASK, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/tasks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${pageToken}`,
        },
        body: JSON.stringify(action.data)
      });
      // console.log('created campaign', result);
      yield put(actions.createTaskSuccess(result));
    } catch (err) {
      // console.log('create campaign error', err);
      yield put(actions.createTaskError(err.message));
    }
  });
}

export function* deleteTask() {
  yield takeEvery(actions.DELETE_TASK, function*(action) {
    const pageToken = yield select(makeSelectPageToken());

    const url = `${API_ENDPOINT.apiUrl}/tasks/${action.id}`;

    try {
      const result = yield call(
        request,
        url,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${pageToken}`,
          },
        }
      );
      console.log('deleted task', result);
      yield put(actions.deleteTaskSuccess(result._id));
      notification.success({ message: 'Deleted' })
    } catch (error) {
      console.log('delete task error', error.message);
      yield put({
        type: actions.DELETE_TASK_ERROR,
        error: error.message,
      });
      notification.error({ message: error.message });
    }
  });
}

export function* updateTask() {
  yield takeEvery(actions.UPDATE_TASK, function*(action) {
    const pageToken = yield select(makeSelectPageToken());
    const url = `${API_ENDPOINT.apiUrl}/tasks/${action.id}`;

    try {
      const result = yield call(
        request,
        url,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${pageToken}`,
          },
          body: JSON.stringify(action.data),
        }
      );
      // console.log('deleted leads', result);
      yield put(actions.updateTaskSuccess(result));
      notification.success({ message: 'Updated' })
    } catch (error) {
      // console.log('load lead error', error.message);
      yield put({
        type: actions.UPDATE_TASK_ERROR,
        error: error.message,
      });
      notification.error({ message: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadTasks),
    fork(createTask),
    fork(deleteTask),
    fork(updateTask),
  ]);
}
