import { all, fork, takeLatest, select, call, put } from 'redux-saga/effects';
import actions from './actions';
import API_ENDPOINT from '../../settings';
// import { makeSelectToken } from '../auth/selector';
import { makeSelectToken } from '../auth/selector';
// import { makeSelectPages } from '../socialPage/selector';
import request from '../../helpers/request';

export function* createDevice() {
  yield takeLatest(actions.CREATE_DEVICE, function*(action){
    const idToken = yield select(makeSelectToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/devices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(action.data)
      });
      // console.log('created devices', result);
      yield put(actions.createDeviceSuccess(result));
    } catch (err) {
      console.log('create device error', err.message);
      yield put(actions.createDeviceError(err.message));
    }
  });
}

export function* getDevices() {
  yield takeLatest(actions.LOAD_DEVICES, function*(action) {
    const idToken = yield select(makeSelectToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/devices?pages=${action.pageId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      // console.log('loaded devices', result);
      yield put(actions.loadDevicesSuccess(result.rows));
    } catch (err) {
      console.log('load device error', err.message);
      yield put(actions.loadDevicesError(err.message));
    }
  })
}

export function* updateDevice() {
  yield takeLatest(actions.UPDATE_DEVICE, function*(action) {
    const idToken = yield select(makeSelectToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/devices/${action.device.nas_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(action.device)
      });
      // console.log('updated devices', result);
      yield put(actions.updateDeviceSuccess(result));
    } catch (err) {
      console.log('update device error', err.message);
      yield put(actions.updateDeviceError(err.message));
    }
  });
}

export function* deleteDevice() {
  yield takeLatest(actions.DELETE_DEVICE, function*(action) {
    const idToken = yield select(makeSelectToken());
    try {
      const result = yield call(request, `${API_ENDPOINT.apiUrl}/devices/${action.nas_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      console.log('deleted device', result);
      yield put(actions.deleteDeviceSuccess(result));
    } catch (err) {
      console.log('delete device error', err.message);
      yield put(actions.deleteDeviceError(err.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createDevice),
    fork(getDevices),
    fork(updateDevice),
    fork(deleteDevice),
  ]);
}
