const actions = {
  LOAD_PAGE_ITEMS: 'PAGEBODY/LOAD_PAGE_ITEMS',
  LOAD_PAGE_ITEMS_SUCCESS: 'PAGEBODY/LOAD_PAGE_ITEMS_SUCCESS',
  LOAD_PAGE_ITEMS_ERROR: 'PAGEBODY/LOAD_PAGE_ITEMS_ERROR',
  CREATE_PAGE_ITEM: 'PAGEBODY/CREATE_PAGE_ITEM',
  CREATE_PAGE_ITEM_SUCCESS: 'PAGEBODY/CREATE_PAGE_ITEM_SUCCESS',
  CREATE_PAGE_ITEM_ERROR: 'PAGEBODY/CREATE_PAGE_ITEM_ERROR',
  EDIT_PAGE_ITEM: 'PAGEBODY/EDIT_PAGE_ITEM',
  EDIT_PAGE_ITEM_SUCCESS: 'PAGEBODY/EDIT_PAGE_ITEM_SUCCESS',
  EDIT_PAGE_ITEM_ERROR: 'PAGEBODY/EDIT_PAGE_ITEM_ERROR',
  DELETE_PAGE_ITEM: 'PAGEBODY/DELETE_PAGE_ITEM',
  DELETE_PAGE_ITEM_SUCCESS: 'PAGEBODY/DELETE_PAGE_ITEM_SUCCESS',
  DELETE_PAGE_ITEM_ERROR: 'PAGEBODY/DELETE_PAGE_ITEM_ERROR',
  MODIFY_PAGE_ITEM_ATTR: 'PAGEBODY/MODIFY_PAGE_ITEM_ATTR',
  loadPageItems: (limit, skip) => ({
    type: actions.LOAD_PAGE_ITEMS,
    limit,
    skip,
  }),
  loadPageItemsSuccess: (items, skip) => ({
    type: actions.LOAD_PAGE_ITEMS_SUCCESS,
    items,
    skip,
  }),
  loadPageItemsError: error => ({
    type: actions.LOAD_PAGE_ITEMS_ERROR,
    error,
  }),
  createPageItem: data => ({
    type: actions.CREATE_PAGE_ITEM,
    data,
  }),
  createPageItemSuccess: item => ({
    type: actions.CREATE_PAGE_ITEM_SUCCESS,
    item,
  }),
  createPageItemError: error => ({
    type: actions.CREATE_PAGE_ITEM_ERROR,
    error,
  }),
  editPageItem: (eid, data) => ({
    type: actions.EDIT_PAGE_ITEM,
    eid,
    data,
  }),
  editPageItemSuccess: item => ({
    type: actions.EDIT_PAGE_ITEM_SUCCESS,
    item,
  }),
  editPageItemError: error => ({
    type: actions.EDIT_PAGE_ITEM_ERROR,
    error,
  }),
  deletePageItem: (eid) => ({
    type: actions.DELETE_PAGE_ITEM,
    eid,
  }),
  deletePageItemSuccess: item => ({
    type: actions.DELETE_PAGE_ITEM_SUCCESS,
    item,
  }),
  deletePageItemError: error => ({
    type: actions.DELETE_PAGE_ITEM_ERROR,
    error,
  }),
  modifyPageItemAttr: (eid, attribute, value) => ({
    type: actions.MODIFY_PAGE_ITEM_ATTR,
    eid,
    attribute,
    value,
  })
};

export default actions;
